/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import mqtt from 'mqtt';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
import store from '@/store';
import topicGroups from './topics';
import router from '../../router';

const SUBACK_SUCCESS_CODES = [0, 1, 2];
/**
 * Concat all topics from topicGroups methods to a single array
 * @returns {*[]}
 */
const concatAllTopics = () => Object.keys(topicGroups).reduce((all, group) => [...all, ...topicGroups[group]()], []);
/**
 * Filter topics that have according matchBy defined and map them to array of topics
 * @param topics - Collection of defined topics from topics.js file
 * @returns {*}
 */
const prepareTopicsForClient = (topics) => topics.filter((t) => t.matchBy).map((item) => item.topic);

/**
 * Handle messages received for subscribed topics
 * by matching received topic with matchBy or name param from collection of defined topics
 * from topics.js file and dispatch matching store action defined in same collection
 * @param topic - topic received from mqtt broker
 * @param msg - message received from mqtt broker
 */
const handleMessages = (topic, msg) => {
  const parsedMsg = JSON.parse(msg);
  const matchingTopic = concatAllTopics().find((t) => {
    // matching by the name of topic
    if (t.name && parsedMsg && parsedMsg.name === t.name) {
      return topic;
    }
    // matching by the matchBy prop
    return topic.includes(t.matchBy);
  });
  if (!matchingTopic) {
    return;
  }

  if (!store._actions[matchingTopic.action]) {
    return;
  }
  /**
   * idea is to dispatch a action per topic,
   * and then use mutators or actions to distribute
   * messages further, based on message params (name, action etc.)
   */
  const topicParams = topic.split('/');
  if (parsedMsg.name === 'WorkloadCtrl') {
    parsedMsg.params.sessionId = `${topicParams[1]}-${topicParams[3]}`;
  }
  store.dispatch(matchingTopic.action, parsedMsg);
};

const generateId = () => uuidv4().substring(0, 8);

const getLocalStorageItemByName = (name) => {
  const item = localStorage.getItem(name);
  if (!item) {
    throw new Error(`mqtt: get ${name} => Not logged in!`);
  }
  return JSON.parse(item);
};

const getSessionId = () => {
  const session = getLocalStorageItemByName('session');
  return session.sessionId;
};

const getUsername = () => {
  const userDetails = getLocalStorageItemByName('userDetails');
  return userDetails.username;
};

const promises = {};

class Mqtt {
  /**
   * Mqtt.js client
   * @type {{}}
   */
  client = {};

  /**
   * Install method for Vue plugin
   * @param Vue - Vue instance
   */
  // eslint-disable-next-line no-shadow
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$mqtt = this;
  }

  logoutTimeout = null;

  timeoutTime = 60000;

  offlineEventHandler = async () => {
    await store.dispatch('auth/is_services_unavailable', { service: 'Emq', isUnavailable: true });
    this.logoutTimeout = setTimeout(async () => {
      await router.push({ name: 'Login' });
      await this.disconnect();
      await store.dispatch('auth/clearLocalStorage');
      await store.dispatch('node-tree/reset_state_to_default_values');
      await store.dispatch('utils/_api_request_handler/show_permanent_toast', true);
    }, this.timeoutTime);
  };

  onConnect = async () => {
    clearTimeout(this.logoutTimeout);
    await store.dispatch('auth/is_services_unavailable', { service: 'Emq', isUnavailable: false });
    await this.subscribeTo('alwaysSubscribed');
    const session = localStorage.getItem('session');
    const { userId, sessionId } = JSON.parse(session);
    await this.subscribeTo('authUser', { userId, sessionId });
  };

  /**
   * Connect to mqtt broker and declare mqtt events
   * @param sessionId - authorization token
   * @param brokerUrl - Url of the broker
   */
  // eslint-disable-next-line no-restricted-globals
  connect({ brokerUrl = `wss://${location.hostname}/wss` } = {}) {
    // eslint-disable-next-line no-restricted-globals
    if (this.client && this.client.connected) {
      return;
    }

    try {
      this.client = mqtt.connect(brokerUrl, {
        username: getUsername(),
        password: 'foo',
        clientId: `w-${getSessionId()}-${generateId()}`,
        keepalive: 20, // seconds, 0 is the default, can be any positive number
        reconnectPeriod: 10 * 1000,
        protocolVersion: 5,
      });
    } catch (e) {
      Vue.prototype.$log.debug(`Cannot connect to mqtt:${e.message || e}`);
    }

    this.client.on('connect', async () => {
      await this.onConnect();
    });

    this.client.on('message', (topic, msg) => handleMessages(topic, msg.toString()));

    this.client.on('offline', async () => {
      if (!window.Cypress) {
        await this.offlineEventHandler();
      }
    });
  }

  /**
   * Pass topic group to subscribe to those topics and according params object
   * @param topicGroup
   * @param params
   * @returns {Promise<void>}
   */
  // eslint-disable-next-line consistent-return
  async subscribeTo(topicGroup = 'alwaysSubscribed', params = {}) {
    try {
      // eslint-disable-next-line no-param-reassign
      params.sessionId = getSessionId();
      if (this.client && this.client.subscribe) {
        const result = await this.client.subscribe(prepareTopicsForClient(topicGroups[topicGroup](params)));
        // If the subscription fails - log the topic and error code
        if (Array.isArray(result) && result.length) {
          result.forEach(({ topic, qos }) => {
            if (!SUBACK_SUCCESS_CODES.includes(qos)) {
              Vue.prototype.$log.debug(`Failed to subscribe to '${topic}' - not authorized (${qos})`);
            }
          });
        }
      }
    } catch (e) {
      Vue.prototype.$log.debug(e.message || e);
    }
  }

  /**
   * Pass topic group to unsubscribe to those topics and according params object
   * @param topicGroup
   * @param params
   * @returns {Promise<void>}
   */
  async unsubscribeFrom(topicGroup = 'alwaysSubscribed', params = null) {
    try {
      if (this.client && this.client.connected) {
        await this.client.unsubscribe(prepareTopicsForClient(topicGroups[topicGroup](params)));
      }
    } catch (e) {
      Vue.prototype.$log.debug(e.message || e);
    }
  }

  // eslint-disable-next-line consistent-return
  async publish({ topic, params }) {
    try {
      // eslint-disable-next-line no-param-reassign
      params.uid = generateId();
      // eslint-disable-next-line no-param-reassign
      params.sender = `cli/${getSessionId()}`;
      const promise = new Promise((resolve, reject) => {
        promises[params.uid] = { resolve, reject };
      });
      this.client.publish(topic, JSON.stringify(params), { qos: 0 }, (err) => {
        if (err) {
          Vue.prototype.$log.debug(`mqtt:publish:${err.message || err}`);
        }
      });
      return promise;
    } catch (e) {
      Vue.prototype.$log.debug(`mqtt:publish:${e.message || e}`);
    }
  }

  publishToNode(node, commandParams) {
    const topic = `oblo/${node.userId}/gtw/${node.serialNumber}/ohm/req`;
    return this.publish({ topic, params: commandParams });
  }

  publishToService({ service, params, type }) {
    const topic = `oblo/svc/${service}/${type}`;
    this.publish({ topic, params });
  }

  async disconnect() {
    if (this.client && typeof this.client.end === 'function') {
      await this.client.end(false);
    }
    this.client = null;
  }

  isConnected() {
    return this.client && this.client.connected;
  }
}

const mqttInstance = new Mqtt();
Vue.use(mqttInstance);

if (window.Cypress) {
  window.vmqtt = mqttInstance;
}

/* develblock:start */
export const privateMethods = {
  concatAllTopics,
  prepareTopicsForClient,
  handleMessages,
};
/* develblock:end */

export default mqttInstance;
