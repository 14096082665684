/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import store from '@/store';
import BaseApiService from './base.api-service';

const PATHV3 = 'nerve/v3/workloads';
class ComposeWorkloadsApiService extends BaseApiService {
  getComposeWorkloads(data) {
    return this.get(`${PATHV3}/${data._id}`, data, { authRequired: true });
  }

  getComposeWorkload({ id, ignoreInterceptor }) {
    return this.get(`${PATHV3}/${id}`, { authRequired: true, ignoreInterceptor });
  }

  getVersionList(id) {
    return this.get(`${PATHV3}/${id}/versions`, { authRequired: true });
  }

  createNewComposeWorkload(param) {
    const options = {
      headers: { 'Content-Type': undefined },
      onUploadProgress: (e) => {
        const progressPercentage = (e.loaded / e.total) * 100;
        store.dispatch('workloads/set_progress', progressPercentage);
      },
      cancelToken: param.token,
    };
    return this.post(`${PATHV3}`, param.payload, options, { authRequired: true });
  }

  updateComposeWorkload({ id, data }) {
    return this.patch(`${PATHV3}/${id}`, data, { authRequired: true });
  }

  deleteComposeWorkload(param) {
    return this.delete(`${PATHV3}/${param._id}`, { authRequired: true });
  }

  fetchComposeWorkloadVersionById(payload) {
    return this.get(`${PATHV3}/${payload.wlId}/versions/${payload.verId}`, {
      authRequired: true,
      ignoreInterceptor: payload.ignoreInterceptor,
    });
  }

  createComposeWorkloadVersion({ payload, token }) {
    const options = {
      headers: { 'Content-Type': undefined },
      onUploadProgress: (e) => {
        const progressPercentage = (e.loaded / e.total) * 100;
        store.dispatch('workloads/set_progress', progressPercentage);
      },
      cancelToken: token,
    };
    return this.post(`${PATHV3}/${payload.wlId}/versions`, payload.data, options, {
      authRequired: true,
    });
  }

  updateComposeWorkloadVersion({ payload, token }) {
    const options = {
      headers: { 'Content-Type': undefined },
      onUploadProgress: (e) => {
        const progressPercentage = (e.loaded / e.total) * 100;
        store.dispatch('workloads/set_progress', progressPercentage);
      },
      cancelToken: token,
    };
    return this.patch(`${PATHV3}/${payload.wlId}/versions/${payload.verId}`, payload.data, options, {
      authRequired: true,
    });
  }

  deleteComposeWorkloadVersion(param) {
    return this.delete(`${PATHV3}/${param.wlId}/versions/${param.verId}`, { authRequired: true });
  }

  checkComposeFile(data) {
    return this.post(`${PATHV3}/compose`, data, { authRequired: true });
  }

  checkStatusOfImages(data) {
    return this.post('nerve/registry/check-images-status', data, { authRequired: true });
  }

  fetchFiles(param) {
    return this.get(`${PATHV3}/${param.wlId}/versions/${param.verId}/files`, {
      authRequired: true,
    });
  }

  addFile(param) {
    return this.post(`${PATHV3}/${param.wlId}/versions/${param.verId}/files`, param.data, {
      authRequired: true,
    });
  }

  editFile(param) {
    return this.patch(`${PATHV3}/${param.wlId}/versions/${param.verId}/files/${param.fileId}`, param.data, {
      authRequired: true,
    });
  }

  fetchComposeFile(param) {
    return this.get(`${PATHV3}/compose/${param.wlId}/versions/${param.verId}/files/${param.fileId}`, {
      authRequired: true,
    });
  }

  cancelComposeWorkloadVersionCreation(payload) {
    return this.patch(`${PATHV3}/${payload.wlId}/versions/${payload.verId}/files/cancel`, {}, { authRequired: true });
  }

  defineAllFiles(param) {
    return this.post(`${PATHV3}/${param.wlId}/versions/${param.verId}/define-all-files`, param.data, {
      authRequired: true,
    });
  }
}

export default new ComposeWorkloadsApiService();
