/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2020. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import ResetPasswordApiService from '@/services/api/reset-password.api-service';
import { set } from '@/utils/vuex';

export default {
  state: {
    isReset: false,
  },
  mutations: {
    SET_IS_RESET: set('isReset'),
  },
  getters: {
    isReset: (state) => state.isReset,
  },
  actions: {
    async isReset({ commit }, resetToken) {
      const res = await ResetPasswordApiService.isReset(resetToken);
      commit('SET_IS_RESET', res.isReset);
    },
    async resetPassword(_, params) {
      await ResetPasswordApiService.resetPassword(params);
    },
  },
};
