/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import axios from 'axios';
import { isEqual, cloneDeep } from 'lodash';
import { diff } from 'deep-object-diff';
import yaml2json from 'js-yaml';
import WorkloadsApiService from '@/services/api/workloads.api-service';
import WorkloadModel from '@/model/workloads/workload.model';
import WorkloadVersionModel from '@/model/workloads/workload-version.model';
import ComposeWorkloadVersionModel from '@/model/workloads/compose-workload-version.model';
import WorkloadsHelper from '@/store/helpers/workloads.helper';
import RemoteConnectionModel from '@/model/node/remoteConnection.model';
import store from '@/store';
import ComposeWorkloadsApiService from '@/services/api/compose-workload.api-service';
import ComposeWorkloadModel from '@/model/workloads/compose-workload.model';
import DockerConfigurationStorageModel from '@/model/workloads/docker-configuration-storage.model';
import ComposeServiceModel from '@/model/workloads/compose-service.model';
import ComposeFileModel from '@/model/workloads/compose-file.model';
import i18n from '@/i18n';
import shared from '@/helpers/shared';

const { CancelToken } = axios;
let source = CancelToken.source();

const defaultDialogParams = {
  text: '',
};

export default {
  state: {
    workloadDetails: {},
    workloadsList: [],
    count: 0,
    workload: {},
    version: {},
    versionList: [],
    versionListWithoutFilter: [],
    dialogVmParams: defaultDialogParams,
    dialogVm: false,
    applyWorkloadConfigurationDialog: false,
    applyWorkloadConfigurationDialogParams: {
      title: '',
    },
    updatedWorkload: {},
    uploadedFile: {},
    uploadedXmlFile: {},
    progress: 0,
    nameAndDesc: {
      name: '',
      description: '',
      internalDockerRegistry: true,
    },
    volumeNameList: [],
    selectedVolumeName: '',
    oldVersion: {},
    isSaveEnabled: false,
    delete: false,
    isAddEditWorkloadVersionPage: false,
    formChangedFlag: false,
    uploadedComposeFile: {},
    fileContent: '',
    composeWorkload: {},
    volumeNameListCompose: {},
    uniqueInfo: {},
    versionId: '',
    versionWithStatus: {},
    oldNameAndDesc: {
      name: '',
      description: '',
      internalDockerRegistry: true,
    },
    newDeployName: '',
    isComposeFileValid: true,
    downloadCounter: {},
    changedDockerImages: {},
    originalFiles: [],
    selectedDeployedWorkloadVersionTimestamp: null,
    isTimestampFromActiveRC: false,
    statusOfImages: [],
  },

  mutations: {
    SET_WORKLOAD_LIST(state, workloads) {
      state.workloadsList = workloads.data.map((workload) =>
        workload.type !== 'docker-compose' ? new WorkloadModel(workload) : new ComposeWorkloadModel(workload),
      );
      state.count = workloads.count;
    },
    REMOVE_WORKLOAD(state, wl) {
      state.workloadsList.splice(
        state.workloadsList.findIndex((workload) => workload._id === wl.id),
        1,
      );
    },
    SET_WORKLOAD(state, wl) {
      state.workload = new WorkloadModel(wl);
      state.versionList =
        wl && wl.versions ? wl.versions.map((version) => new WorkloadVersionModel({ version, type: wl.type })) : [];
    },
    SET_COMPOSE_WORKLOAD(state, wl) {
      state.composeWorkload = new ComposeWorkloadModel(wl);
      state.versionList =
        wl && wl.versions
          ? wl.versions.map((version) => new ComposeWorkloadVersionModel({ version, type: wl.type }))
          : [];
    },
    SET_VERSION(state, params) {
      state.volumeNameList = [];
      state.selectedVolumeName = '';
      if (params.versionId === 'new') {
        state.version = new WorkloadVersionModel({ version: {}, type: state.workload.type });
        return;
      }
      state.version = new WorkloadVersionModel({
        version: state.workload.versions.find((version) => version._id === params.versionId),
        type: state.workload.type,
      });
      if (state.version.workloadProperties && state.version.workloadProperties.docker_volumes) {
        state.volumeNameList.push(i18n.t('workloadVersion.emptyState'));
        state.version.workloadProperties.docker_volumes.forEach((volume) => {
          state.volumeNameList.push(volume.volumeName);
          if (volume.configurationStorage === true) {
            state.selectedVolumeName = volume.volumeName;
          }
        });
        return;
      }
      state.selectedVolumeName = '';
    },
    SET_VERSIONS(state, versions, type) {
      state.versionList = versions.map((version) => new WorkloadVersionModel({ version, type }));
    },
    SET_COMPOSE_VERSION(state, params) {
      if (params.versionId === 'new') {
        state.version = new ComposeWorkloadVersionModel({
          version: {},
          type: state.composeWorkload.type,
        });
        return;
      }
      state.version = new ComposeWorkloadVersionModel({
        version: params.version,
        type: state.composeWorkload.type,
      });
    },
    SET_COMPOSE_VERSIONS(state, versions) {
      state.versionList = versions.map((version) => new ComposeWorkloadVersionModel({ version }));
    },
    REMOVE_VERSIONS_WITHOUT_FILTER(state, vers) {
      state.versionList.splice(
        state.versionList.findIndex((version) => version._id === vers.verId),
        1,
      );
    },
    FETCH_VERSIONS(state, versions) {
      state.versionListWithoutFilter = versions.map(
        (version) => new WorkloadVersionModel({ version, type: state.workload.type }),
      );
    },
    FETCH_COMPOSE_VERSIONS(state, versions) {
      state.versionListWithoutFilter = versions.map(
        (version) => new ComposeWorkloadVersionModel({ version, type: state.composeWorkload.type }),
      );
    },
    SHOW_DIALOG_VM_VIDEO_OPTION: (state, params) => {
      state.dialogVmParams = Object.assign(defaultDialogParams, params);
      state.dialogVm = true;
    },
    CLOSE_DIALOG_VM_VIDEO_OPTION: (state) => {
      state.dialogVm = false;
    },
    SHOW_APPLY_WORKLOAD_CONFIGURATION: (state, params) => {
      state.applyWorkloadConfigurationDialog = true;
      state.applyWorkloadConfigurationDialogParams = params;
    },
    SET_DOWNLOADING_STATE(state, payload) {
      state.versionList.map((v) => {
        if (v._id === payload.version || v._id === payload.versionId) {
          if (!state.downloadCounter[v._id]) {
            state.downloadCounter[v._id] = 1;
          }
          if (v.summarizedFileStatuses && v.summarizedFileStatuses.downloading !== state.downloadCounter[v._id]) {
            v.isDownloading = true;
            state.downloadCounter[v._id] += 1;
            return;
          }
          v.isDownloading = false;
          state.downloadCounter[v._id] = 1;
        }
      });
    },
    SET_DEPLOYABLE(state, payload) {
      state.versionList.map((v) => {
        if (v._id === payload.version || v._id === payload.versionId) {
          v.isDeployable = true;
        }
      });
    },
    SET_ERROR_MESSAGES(state, payload) {
      state.versionList.map((v) => {
        if (v._id === payload.version || v._id === payload.versionId) {
          v.errorMessage = payload.error;
          if (payload.error === 'nerve_workload_file_download_failed') {
            v.isFailed = true;
          }
          if (!state.downloadCounter[v._id]) {
            state.downloadCounter[v._id] = 1;
          }
          if (
            payload.error !== 'nerve_workload_files_cancelled' &&
            v.summarizedFileStatuses &&
            v.summarizedFileStatuses.downloading !== state.downloadCounter[v._id]
          ) {
            v.isDownloading = true;
            state.downloadCounter[v._id] += 1;
            return;
          }
          v.isDownloading = false;
          state.downloadCounter[v._id] = 1;
        }
      });
    },
    SAVE_UPLOADED_FILE(state, file) {
      state.uploadedFile = file;
    },
    SAVE_CHANGED_DOCKER_IMAGES(state, payload) {
      const { file, imageName, changedFile } = payload;
      const key =
        Object.keys(state.changedDockerImages)?.find((image) =>
          shared.compareTwoDockerImageNamesAreEqual(image, imageName),
        ) || imageName;
      state.changedDockerImages[key] = {
        ...(file && { file }),
        username: changedFile.username,
        password: changedFile.password,
        source: changedFile.source,
        origin: changedFile.origin,
        status: changedFile.status,
        _id: changedFile._id,
      };
    },
    REMOVE_UPLOADED_DOCKER_IMAGE(state, payload) {
      const { imageName, removeAll } = payload;
      if (removeAll) {
        state.changedDockerImages = {};
        return;
      }
      delete state.changedDockerImages[imageName];
    },
    SAVE_CHANGED_DOCKER_IMAGES_SPECIFIC_PROPERTY(state, payload) {
      const { imageName, property, value } = payload;
      const key =
        Object.keys(state.changedDockerImages)?.find((image) =>
          shared.compareTwoDockerImageNamesAreEqual(image, imageName),
        ) || imageName;
      if (state.changedDockerImages[key]) {
        state.changedDockerImages[key][property] = value;
      }
    },
    SAVE_UPLOADED_XML_FILE(state, file) {
      state.uploadedXmlFile = file;
    },
    SAVE_UPLOADED_COMPOSE_FILE(state, file) {
      state.uploadedComposeFile = file;
    },
    SET_PROGRESS(state, progress) {
      state.progress = progress;
    },
    SET_NAME_AND_DESC(state, value) {
      state.nameAndDesc.name = value.name;
      state.nameAndDesc.description = value.desc;
      state.nameAndDesc.internalDockerRegistry = value.internalDockerRegistry;
    },
    SET_SAVE_ENABLED(state, value) {
      state.isSaveEnabled = value;
    },
    SET_OLD_VERSION(state, id) {
      state.oldVersion = cloneDeep(state.version);
    },
    REMOVE_RC_FROM_VERSION(state, value) {
      state.version.remoteConnections.splice(value.index, 1);
    },
    REMOVE_ALL_RC_FROM_VERSION(state) {
      state.version.remoteConnections = [];
    },
    ADD_EDIT_RC_FROM_WORKLOAD(state, rc) {
      // rc already exist -> edit the existing rc
      if (rc.index !== undefined) {
        state.version.remoteConnections.splice(rc.index, 1, new RemoteConnectionModel(rc));
        return;
      }
      state.version.remoteConnections.push(new RemoteConnectionModel(rc));
    },
    SET_ADD_EDIT_WORKLOAD_VERSION_ROUTE(state, value) {
      state.isAddEditWorkloadVersionPage = value;
    },
    SET_FORM_CHANGE_FLAG(state, value) {
      state.formChangedFlag = value;
    },
    SET_JSON_FILE_CONTENT(state, { composeFile, files }) {
      if (typeof composeFile === 'string') {
        composeFile = yaml2json.load(composeFile);
      }
      state.fileContent = composeFile;
      const registries = [];
      if (composeFile && composeFile.services) {
        const serviceNames = Object.getOwnPropertyNames(composeFile.services);
        serviceNames.forEach((name) => {
          // Find all services in docker-compose file and set configuration storages for all of them
          if (name !== '__ob__') {
            let dockerConfigurationStorage;
            state.version.workloadSpecificProperties.dockerConfigurationStorage.forEach((storage) => {
              if (storage.serviceName === name) {
                dockerConfigurationStorage = storage;
              }
            });
            if (!dockerConfigurationStorage) {
              dockerConfigurationStorage = new DockerConfigurationStorageModel({
                serviceName: name,
              });
            }
            state.version.services.push(
              new ComposeServiceModel({
                serviceName: name,
                dockerFileOption: composeFile.services[name].image ? composeFile.services[name].image : '',
                ...(composeFile.services[name].image && {
                  dockerFilePath: composeFile.services[name].image,
                }),
                dockerConfigurationStorage,
                ...(files && {
                  file: new ComposeFileModel(
                    files.find((file) =>
                      shared.compareTwoDockerImageNamesAreEqual(
                        file.source,
                        composeFile.services[name].image,
                        state.composeWorkload.internalDockerRegistry,
                      ),
                    ),
                  ),
                }),
                ...(files &&
                  files.find((file) =>
                    shared.compareTwoDockerImageNamesAreEqual(
                      file.source,
                      composeFile.services[name].image,
                      state.composeWorkload.internalDockerRegistry,
                    ),
                  ) && {
                    errorMessage: files.find((file) =>
                      shared.compareTwoDockerImageNamesAreEqual(
                        file.source,
                        composeFile.services[name].image,
                        state.composeWorkload.internalDockerRegistry,
                      ),
                    ).lastError,
                  }),
              }),
            );
            state.originalFiles.push({
              serviceName: name,
              file: files
                ? new ComposeFileModel(
                    files.find((file) =>
                      shared.compareTwoDockerImageNamesAreEqual(
                        file.source,
                        composeFile.services[name].image,
                        state.composeWorkload.internalDockerRegistry,
                      ),
                    ),
                  )
                : {},
            });
            // If there are files with failed status they should be on the top of the list
            // Available files are next and than the rest
            state.version.services.sort((a, b) => {
              const statusOrder = {
                failed: 0,
                available: 1,
                downloading: 2,
                pending: 2,
                cancelled: 2,
              };

              const aStatus = a.file.status;
              const bStatus = b.file.status;

              if (statusOrder[aStatus] < statusOrder[bStatus]) {
                return -1; // a should come before b
              }
              if (statusOrder[aStatus] > statusOrder[bStatus]) {
                return 1; // b should come before a
              }
              return 0; // leave the order unchanged
            });
            let registry = '';
            if (composeFile.services[name].image.includes('/')) {
              const lastSlashIndex = composeFile.services[name].image.lastIndexOf('/');
              registry = composeFile.services[name].image.slice(0, lastSlashIndex);
            } else {
              registry = 'docker.io';
            }
            if (!registry.includes('.')) {
              registry = `docker.io/${registry}`;
            }
            const file = files
              ? files.find((f) =>
                  shared.compareTwoDockerImageNamesAreEqual(
                    f.source,
                    composeFile.services[name].image,
                    state.composeWorkload.internalDockerRegistry,
                  ),
                )
              : {};
            let username = '';
            let password = '';
            if (file) {
              username = file.username || '';
              password = file.password || '';
            }
            if (!registries.find((reg) => reg.registryName === registry)) {
              registries.push({
                registryName: registry,
                username,
                password,
                textFieldType: 'text',
                hidePassword: true,
              });
            } else {
              registries.map((reg) => {
                if (reg.registryName === registry) {
                  if (username) {
                    reg.username = username;
                  }
                }
              });
            }
            const service = composeFile.services[name];
            // Set list of volume names for docker configuration storage for each service
            const models = WorkloadsHelper.findNamedVolumes(service.volumes).map(
              (item) => new DockerConfigurationStorageModel({ ...item, serviceName: name }),
            );
            if (models.length !== 0) {
              // Add empty object to list of volumes names to provide user option to deselect configuration storage
              models.unshift({
                volumeName: i18n.t('workloadVersion.emptyState'),
                serviceName: name,
              });
            }
            state.volumeNameListCompose[name] = models;
          }
        });
        state.version.registries = registries;
      }
    },
    SET_UNIQUE_INFO(state, uniqueInfo) {
      state.uniqueInfo = uniqueInfo;
    },
    SET_VERSION_WITH_STATUS(state, payload) {
      state.versionWithStatus = {
        versionId: payload.versionId,
        isDownloading: payload.status === 'downloading',
        isDeployable: payload.status === 'available',
      };
    },
    SET_OLD_NAME_AND_DESC(state, value) {
      state.oldNameAndDesc.name = value.name;
      state.oldNameAndDesc.description = value.desc;
      state.oldNameAndDesc.internalDockerRegistry = value.internalDockerRegistry;
    },
    SET_NEW_DEPLOY_NAME(state, newDeployName) {
      state.newDeployName = newDeployName;
    },
    SET_FILE_CONTENT(state, value) {
      state.fileContent = value;
    },
    SET_SELECTED_VOLUME_NAME(state, value) {
      state.selectedVolumeName = value;
    },
    SET_COMPOSE_FILE_FLAG(state, value) {
      state.isComposeFileValid = value;
    },
    SET_SELECTED_DEPLOYED_WORKLOAD_VERSION_TIMESTAMP(state, { timestamp, isActiveRc }) {
      state.selectedDeployedWorkloadVersionTimestamp = timestamp;
      state.isTimestampFromActiveRC = isActiveRc;
    },
    SET_STATUS_OF_IMAGES(state, value) {
      state.statusOfImages = value;
    },
  },

  getters: {
    list: (state) => state.workloadsList,
    count: (state) => state.count,
    getVersions: (state) => state.versionList,
    getWorkload: (state) => state.workload,
    getComposeWorkload: (state) => state.composeWorkload,
    getVersion: (state) => state.version,
    versionCount: (state) => state.versionList.length,
    getDialogVmParams: (state) => () => state.dialogVmParams,
    getDialogVmState: (state) => () => state.dialogVm,
    getApplyWorkloadConfigurationDialogState: (state) => () => state.applyWorkloadConfigurationDialog,
    getApplyWorkloadConfigurationDialogParams: (state) => () => state.applyWorkloadConfigurationDialogParams,
    getUploadedFile: (state) => state.uploadedFile,
    getUploadedXmlFile: (state) => state.uploadedXmlFile,
    getProgress: (state) => state.progress,
    getNameAndDesc: (state) => state.nameAndDesc,
    getVolumeNameList: (state) => state.volumeNameList,
    getSelectedVolumeName: (state) => state.selectedVolumeName,
    isSaveEnabled: (state) => state.isSaveEnabled,
    isAddEditWorkloadVersionPage: (state) => state.isAddEditWorkloadVersionPage,
    getFormChangedFlag: (state) => state.formChangedFlag,
    getUploadedComposeFile: (state) => state.uploadedComposeFile,
    fileContent: (state) => state.fileContent,
    getVolumeNameListCompose: (state) => state.volumeNameListCompose,
    uniqueInfo: (state) => state.uniqueInfo,
    getOldNameAndDesc: (state) => state.oldNameAndDesc,
    getNewDeployName: (state) => state.newDeployName,
    getIsComposeFileValid: (state) => state.isComposeFileValid,
    getChangedDockerImages: (state) => state.changedDockerImages,
    getOriginalFiles: (state) => state.originalFiles,
    getSelectedDeployedWorkloadVersionTimestamp: (state) => state.selectedDeployedWorkloadVersionTimestamp,
    getIsTimestampFromActiveRC: (state) => state.isTimestampFromActiveRC,
    getOldVersionRemoteConnections: (state) => state.oldVersion?.remoteConnections || [],
    getStatusOfImages: (state) => state.statusOfImages,
  },

  actions: {
    disable_save_button({ commit }) {
      commit('SET_SAVE_ENABLED', false);
    },
    async fetch({ state, commit }, data) {
      if (state.delete && state.workloadsList.length === 0 && data.page > 1) {
        data.page -= 1;
      }
      const params = {
        filterBy: WorkloadsHelper.handleFilterBy(data),
        limit: data.itemsPerPage || 10,
        page: data.page || 1,
        order: WorkloadsHelper.handleOrder(data),
      };
      commit('SET_WORKLOAD_LIST', await WorkloadsApiService.fetchWorkloads({ params }));
    },
    save_uploaded_file({ commit }, file) {
      commit('SAVE_UPLOADED_FILE', file);
    },
    save_changed_docker_images({ commit }, payload) {
      commit('SAVE_CHANGED_DOCKER_IMAGES', payload);
    },
    remove_changed_docker_images({ commit }, { imageName, removeAll }) {
      commit('REMOVE_UPLOADED_DOCKER_IMAGE', { imageName, removeAll });
    },
    save_changed_docker_images_specific_property({ commit }, payload) {
      commit('SAVE_CHANGED_DOCKER_IMAGES_SPECIFIC_PROPERTY', payload);
    },
    save_uploaded_xml_file({ commit }, file) {
      commit('SAVE_UPLOADED_XML_FILE', file);
    },
    save_uploaded_compose_file({ commit }, file) {
      commit('SAVE_UPLOADED_COMPOSE_FILE', file);
    },
    async verify_uploaded_compose_file({ commit }, file) {
      const result = await ComposeWorkloadsApiService.checkComposeFile(file);
      commit('SET_JSON_FILE_CONTENT', { composeFile: result });
    },
    verify_uploaded_compose_file_failed({ commit, dispatch }) {
      dispatch('save_uploaded_compose_file', {});
      commit('SET_JSON_FILE_CONTENT', { composeFile: '' });
    },
    async delete_workload({ state, commit }, param) {
      state.delete = true;
      await WorkloadsApiService.deleteWorkload(param);
      commit('REMOVE_WORKLOAD', param);
    },
    async delete_compose_workload({ state, commit }, param) {
      state.delete = true;
      await ComposeWorkloadsApiService.deleteComposeWorkload(param);
      commit('REMOVE_WORKLOAD', param);
    },
    async delete_version({ commit }, param) {
      if (param.type === 'docker-compose') {
        await ComposeWorkloadsApiService.deleteComposeWorkloadVersion(param);
      } else {
        await WorkloadsApiService.deleteVersion(param);
      }
      commit('REMOVE_VERSIONS_WITHOUT_FILTER', param);
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: 'workloadDetail.dialog.successfullyDeleted',
        color: 'success',
        showClose: true,
      });
    },
    async check_files(_, param) {
      await WorkloadsApiService.exportWorkload(param);
    },
    async export_workload(_, param) {
      await WorkloadsApiService.exportWorkload(param);
    },
    async canceled_version_creation(param) {
      await WorkloadsApiService.canceledVersionCreation(param);
    },
    async disable_enable_workload(_, param) {
      param.disabled = !param.disabled;
      await WorkloadsApiService.disableEnableWorkload(param);
    },
    async disable_enable_compose_workload(_, param) {
      param.disabled = !param.disabled;
      await ComposeWorkloadsApiService.updateComposeWorkload({
        id: param._id,
        data: { disabled: param.disabled },
      });
    },
    async get_workload_by_id({ commit }, id) {
      if (id === 'new') {
        commit('SET_WORKLOAD', {});
        return;
      }
      const wl = await WorkloadsApiService.getWlById(id);
      commit('SET_WORKLOAD', wl);
    },
    async get_compose_workload_by_id({ commit, state }, id) {
      if (id === 'new') {
        commit('SET_COMPOSE_WORKLOAD', {});
        return;
      }
      const wl = await ComposeWorkloadsApiService.getComposeWorkload({ id });
      const versions = await ComposeWorkloadsApiService.getVersionList(id);
      wl.versions = versions.data;
      if (wl.versions.length) {
        wl.versions.map((version) => {
          state.downloadCounter[version._id] = 1;
          if (state.versionWithStatus.versionId && version._id === state.versionWithStatus.versionId) {
            version.isDownloading = state.versionWithStatus.isDownloading;
            version.isDeployable = state.versionWithStatus.isDeployable;
          }
        });
      }
      commit('SET_COMPOSE_WORKLOAD', wl);
    },
    async get_version_by_id({ commit, getters, state }, params) {
      if (!params.isUpdate) {
        const versions = getters.getVersions;
        const userPermissions = store.getters['auth/getUserPermissions'];
        if (versions.length && getters.getWorkload._id) {
          if (params.id === 'new') {
            if (userPermissions && !!userPermissions['UI_WORKLOAD:VERSION_CREATE']) {
              versions[versions.length - 1].remoteConnections.forEach((rc) => {
                delete rc.id;
                delete rc._id;
              });
            } else {
              versions[versions.length - 1].remoteConnections = [];
            }
          } else {
            const versionIndex = versions.findIndex((version) => version.id === params.id);
            if (userPermissions && !!userPermissions['UI_WORKLOAD:VERSION_CREATE']) {
              versions[versionIndex].remoteConnections.forEach((rc) => {
                delete rc.id;
                delete rc._id;
              });
            } else {
              versions[versionIndex].remoteConnections = [];
            }
          }
          state.workload.versions = versions;
        }
      }
      if (params.id === 'new' && getters.getWorkload._id !== '') {
        const versions = getters.getVersions;
        if (versions.length) {
          commit('SET_VERSION', {
            versionId: versions[versions.length - 1]._id,
            workloadId: getters.getWorkload._id,
          });
          commit('SET_OLD_VERSION', versions[versions.length - 1]._id);
          return;
        }
      }
      commit('SET_VERSION', { versionId: params.id, workloadId: getters.getWorkload._id });
      commit('SET_OLD_VERSION', params.id);
    },
    initVersion({ commit, getters }) {
      const workload = getters.getWorkload;
      commit(
        'SET_VERSIONS',
        workload.versions.filter((v) => v.isDeployable),
        workload.type,
      );
    },
    async fetchVersion({ commit, getters }, param) {
      const workload = getters.getWorkload;
      param.search = decodeURIComponent(param.search);
      const versions =
        param && param.search && param.search.length
          ? workload.versions.filter((v) => v.name.includes(param.search) && v.isDeployable)
          : workload.versions.filter((v) => v.isDeployable);
      commit('SET_VERSIONS', versions, workload.type);
    },
    async fetchVersionWithoutFilter({ commit, getters }) {
      const versions = getters.getVersions;
      commit('FETCH_VERSIONS', versions);
    },
    async get_compose_version_by_id({ commit, getters, state, dispatch }, params) {
      if (!params.isUpdate) {
        const versions = getters.getVersions;
        if (!versions.length) {
          commit('SET_COMPOSE_VERSION', {
            version: {},
            workloadId: getters.getComposeWorkload._id,
          });
          commit('SET_OLD_VERSION', params.id);
          return;
        }
        const userPermissions = store.getters['auth/getUserPermissions'];
        if (versions.length && getters.getComposeWorkload._id) {
          const version = await ComposeWorkloadsApiService.fetchComposeWorkloadVersionById({
            verId: params.isClone ? params.id : versions[versions.length - 1]._id,
            wlId: getters.getComposeWorkload._id,
          });
          if (userPermissions && !!userPermissions['UI_WORKLOAD:VERSION_CREATE']) {
            version.remoteConnections.forEach((rc) => {
              delete rc.id;
              delete rc._id;
            });
          } else {
            version.remoteConnections = [];
          }
          commit('SET_COMPOSE_VERSION', { version, workloadId: getters.getComposeWorkload._id });
          const files = await dispatch('get_files', {
            versionId: params.isClone ? params.id : versions[versions.length - 1]._id,
            workloadId: getters.getComposeWorkload._id,
          });
          dispatch('set_files', files);
          commit('SET_OLD_VERSION', versions[versions.length - 1]._id);
          return;
        }
      }
      const version = await ComposeWorkloadsApiService.fetchComposeWorkloadVersionById({
        verId: params.id,
        wlId: getters.getComposeWorkload._id,
      });
      commit('SET_COMPOSE_VERSION', { version, workloadId: getters.getComposeWorkload._id });
      const files = await dispatch('get_files', {
        versionId: params.id,
        workloadId: getters.getComposeWorkload._id,
      });
      dispatch('set_files', files);
      files.find((file) => {
        if (file.status === 'failed') {
          state.version.errorMessage = file.lastError;
        }
      });
      commit('SET_OLD_VERSION', params.id);
    },
    async get_files({ commit }, params) {
      const responseFiles = await ComposeWorkloadsApiService.fetchFiles({
        wlId: params.workloadId,
        verId: params.versionId,
      });
      const compose = responseFiles.files.find((file) => file.type === 'compose');
      const composeFile =
        compose && compose._id
          ? await ComposeWorkloadsApiService.fetchComposeFile({
              wlId: params.workloadId,
              verId: params.versionId,
              fileId: compose._id,
            })
          : '';
      commit('SET_JSON_FILE_CONTENT', { composeFile, files: responseFiles.files });
      responseFiles.files.forEach((file) => {
        if (file.type === 'docker-image' && file.status !== 'available') {
          commit('SAVE_CHANGED_DOCKER_IMAGES', { imageName: file.source, changedFile: file });
        }
      });
      return responseFiles.files;
    },
    set_files({ state }, files) {
      files.forEach((file) => {
        if (file.type === 'compose') {
          state.version.composeFile.originalName = file.originalName;
          state.version.composeFile._id = file._id;
        }
      });
    },
    initComposeVersion({ commit, getters }) {
      const workload = getters.getComposeWorkload;
      commit(
        'SET_COMPOSE_VERSIONS',
        workload.versions.filter((v) => v.isDeployable),
      );
    },
    async fetchComposeVersion({ commit, getters }, param) {
      const workload = getters.getComposeWorkload;
      param.search = decodeURIComponent(param.search);
      const versions =
        param && param.search && param.search.length
          ? workload.versions.filter((v) => v.name.includes(param.search) && v.isDeployable)
          : workload.versions.filter((v) => v.isDeployable);
      commit('SET_COMPOSE_VERSIONS', versions, workload.type);
    },
    async fetchComposeVersionWithoutFilter({ commit, getters }) {
      const versions = getters.getVersions;
      commit('FETCH_COMPOSE_VERSIONS', versions);
    },
    async deploy({ commit }, deployInfo) {
      store.dispatch('utils/_api_request_handler/close_toast');
      const deploy = await WorkloadsApiService.deploy(deployInfo);
      commit('SET_NEW_DEPLOY_NAME', deploy.operation_name);
    },
    show_dialog_vm_video_option({ commit }) {
      commit('SHOW_DIALOG_VM_VIDEO_OPTION');
    },
    close_dialog_vm_video_option({ commit }) {
      commit('CLOSE_DIALOG_VM_VIDEO_OPTION');
    },
    async update_workload({ commit }, payload) {
      const { sessionId } = JSON.parse(localStorage.getItem('session'));
      // only user that initiated action (create workload) will receive a messages
      if (payload.success && sessionId === payload.sessionId) {
        if (payload.type === 'docker') {
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'errorMessages.WORKLOAD_DOCKER_IMG_DOWNLOADED_SUCCESSFULLY',
            color: 'green',
            showClose: true,
          });
        }
        commit('SET_DOWNLOADING_STATE', payload);
        commit('SET_DEPLOYABLE', payload);
        return;
      }
      if (payload && payload._id) {
        await WorkloadsApiService.updateWorkload(payload._id);
      }
    },
    async update_compose_workload(_, payload) {
      const { id, data } = payload;
      if (id && data) {
        store.dispatch('utils/_api_request_handler/show_progress_bar_dialog');
        await ComposeWorkloadsApiService.updateComposeWorkload({ id, data });
        store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'workloadDetail.successfullyUpdate',
          color: 'green',
          showClose: true,
        });
      }
    },
    async update_workload_error_messages({ commit }, payload) {
      if (payload.error) {
        commit('SET_ERROR_MESSAGES', payload);
      }
    },
    set_workload({ commit }, workload) {
      commit('SET_WORKLOAD', workload);
    },
    async set_save_enabled({ commit, state }, currentVersion) {
      if (!currentVersion) {
        commit('SET_SAVE_ENABLED', true);
        return;
      }
      if (currentVersion.services) {
        currentVersion.services.forEach((service) => {
          if (service.file && service.file.status !== 'available') {
            commit('SET_SAVE_ENABLED', true);
          }
        });
      }
      if (state?.oldVersion?.services) {
        state.oldVersion.services.forEach((service) => {
          if (service.file && service.file.origin === 'upload') {
            const foundService = currentVersion?.services?.find((s) => s.file.source === service.file.source);
            if (foundService && foundService.file) {
              // In case of upload files assign password and username from
              // current version so that they are not influence save button behavior
              service.file.username = foundService.file.username;
              service.file.password = foundService.file.password;
            }
          }
        });
      }
      const areSame = isEqual(currentVersion, state.oldVersion);
      if (!areSame) {
        const difference = diff(currentVersion, state.oldVersion);
        if (Object.keys(difference).length === 0) {
          commit('SET_SAVE_ENABLED', false);
          return;
        }
        let isSame =
          Object.keys(difference).length === 1 && Object.prototype.hasOwnProperty.call(difference, 'dockerFileOption');
        if (state.oldVersion.workloadSpecificProperties) {
          isSame =
            isSame ||
            (Object.keys(difference).length === 1 &&
              Object.prototype.hasOwnProperty.call(difference, 'workloadSpecificProperties'));
        }
        commit('SET_SAVE_ENABLED', !isSame);
        return;
      }
      commit('SET_SAVE_ENABLED', false);
    },
    async update_version(_, payload) {
      source = CancelToken.source();
      store.dispatch('utils/_api_request_handler/show_progress_bar_dialog');
      await WorkloadsApiService.updateVersion({ payload, token: source.token });
    },
    set_progress({ commit }, progress) {
      commit('SET_PROGRESS', progress);
    },
    cancel_request(_) {
      source.cancel();
    },
    async create_workload(_, payload) {
      source = CancelToken.source();
      store.dispatch('utils/_api_request_handler/show_progress_bar_dialog');
      await WorkloadsApiService.createNewWorkloadVersion({ payload, token: source.token });
    },
    async create_compose_workload(_, payload) {
      source = CancelToken.source();
      store.dispatch('utils/_api_request_handler/show_progress_bar_dialog');
      const wl = await ComposeWorkloadsApiService.createNewComposeWorkload({
        payload,
        token: source.token,
      });
      return wl._id;
    },
    async create_compose_workload_version({ state }, payload) {
      source = CancelToken.source();
      store.dispatch('utils/_api_request_handler/show_progress_bar_dialog');
      const version = await ComposeWorkloadsApiService.createComposeWorkloadVersion({
        payload,
        token: source.token,
      });
      // eslint-disable-next-line no-return-assign
      Object.keys(state.downloadCounter).map((key) => (state.downloadCounter[key] = 1));
      state.downloadCounter[version._id] = 1;

      return version;
    },
    async update_compose_workload_version({ state }, payload) {
      source = CancelToken.source();
      store.dispatch('utils/_api_request_handler/show_progress_bar_dialog');
      const version = await ComposeWorkloadsApiService.updateComposeWorkloadVersion({
        payload,
        token: source.token,
      });
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: 'workloadDetail.successfullyUpdate',
        color: 'green',
        showClose: true,
      });

      // eslint-disable-next-line no-return-assign
      Object.keys(state.downloadCounter).map((key) => (state.downloadCounter[key] = 1));
      state.downloadCounter[version._id] = 1;
      return version;
    },
    upload_file(_, param) {
      return ComposeWorkloadsApiService.addFile(param);
    },
    change_file(_, param) {
      return ComposeWorkloadsApiService.editFile(param);
    },
    async define_all_files(_, param) {
      const response = await ComposeWorkloadsApiService.defineAllFiles(param);
      return response.files;
    },
    setNameAndDesc({ commit }, value) {
      commit('SET_NAME_AND_DESC', value);
    },
    setOldNameAndDesc({ commit }, value) {
      commit('SET_OLD_NAME_AND_DESC', value);
    },
    removeRCFromVersion({ commit }, params) {
      commit('REMOVE_RC_FROM_VERSION', params);
    },
    removeRc({ commit }, params) {
      commit('REMOVE_ALL_RC_FROM_VERSION', params);
    },
    add_edit_rc_from_workload({ commit }, params) {
      commit('ADD_EDIT_RC_FROM_WORKLOAD', params.connection);
    },
    setAddEditWorkloadVersionRoute({ commit }, value) {
      commit('SET_ADD_EDIT_WORKLOAD_VERSION_ROUTE', value);
    },
    form_has_changed({ commit, state }) {
      commit('SET_FORM_CHANGE_FLAG', !state.formChangedFlag);
    },
    set_file_content({ commit }, value) {
      commit('SET_FILE_CONTENT', value);
    },
    async unique_info({ commit }, params) {
      const uniqueInfo = await WorkloadsApiService.getUniqueInfo(params);
      commit('SET_UNIQUE_INFO', uniqueInfo);
    },
    async cancel_compose_version_creation(_, { wlId, verId }) {
      await ComposeWorkloadsApiService.cancelComposeWorkloadVersionCreation({ wlId, verId });
    },
    set_compose_version_status({ commit, dispatch, state }, payload) {
      const { sessionId } = JSON.parse(localStorage.getItem('session'));
      if (payload.status === 'nerve_workload_file_download_failed') {
        dispatch('update_workload_error_messages', {
          error: payload.status,
          version: payload.versionId,
        });
        // only user that initiated action (create workload) will receive a messages
        if (sessionId === payload.sessionId) {
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: payload.errorCode
              ? `errorMessages.${payload.errorCode}`
              : 'errorMessages.WORKLOAD_DOCKER_IMG_DOWNLOAD_ERROR',
            color: 'red',
            showClose: true,
          });
        }
        return;
      }
      if (payload.status === 'nerve_workload_files_cancelled') {
        dispatch('update_workload_error_messages', {
          error: payload.status,
          version: payload.versionId,
        });
        return;
      }
      if (payload.status === 'nerve_workload_file_deleted') {
        dispatch('update_workload_error_messages', {
          error: payload.status,
          version: payload.versionId,
        });
      }
      if (payload.status === 'nerve_workload_file_downloaded') {
        // only user that initiated action (create workload) will receive a messages
        if (sessionId === payload.sessionId) {
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'errorMessages.WORKLOAD_DOCKER_IMG_DOWNLOADED_SUCCESSFULLY',
            color: 'green',
            showClose: true,
          });
        }
        commit('SET_DOWNLOADING_STATE', payload);
        commit('SET_DEPLOYABLE', payload);
      }
    },
    set_compose_file_flag({ commit }, value) {
      commit('SET_COMPOSE_FILE_FLAG', value);
    },

    clear_original_files({ state }) {
      state.originalFiles = [];
    },
    set_selected_deployed_workload_version_timestamp({ commit }, { dateString, isActiveRc }) {
      let timestamp = null;
      if (dateString) {
        // Split the date and time parts
        const [datePart, timePart] = dateString.split(', ');
        // Split the date into day, month, and year
        const [day, month, year] = datePart.split('/').map(Number);
        // Split the time into hours, minutes, and seconds
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
        // Create a new Date object using the parsed values
        const date = new Date(year, month - 1, day, hours, minutes, seconds);
        timestamp = date.getTime();
      }
      commit('SET_SELECTED_DEPLOYED_WORKLOAD_VERSION_TIMESTAMP', { timestamp, isActiveRc });
    },
    async check_status_of_images({ commit }, params) {
      const response = await ComposeWorkloadsApiService.checkStatusOfImages(params);
      commit('SET_STATUS_OF_IMAGES', response.listOfImagesWithStatus);
    },

    mqtt_image_event({ state, commit }, payload) {
      const { name, params } = payload;
      // eslint-disable-next-line default-case
      switch (name) {
        case 'image_pushed_to_registry':
          state.version?.services?.map((service) => {
            if (
              (service.file &&
                service.file.origin === 'docker-repo' &&
                service.file.originalName === params.imageName) ||
              (!service.file.status &&
                service.dockerFilePath.endsWith(params.imageName) &&
                service.dockerFileOption.endsWith(params.imageName))
            ) {
              service.file.status = 'available';
              const statuses = state.statusOfImages.map((statusOfImage) => {
                if (statusOfImage.image === params.imageName) {
                  statusOfImage.available = true;
                }
                return statusOfImage;
              });

              commit('SET_STATUS_OF_IMAGES', statuses);
            }
            return service;
          });
          params.workloads?.forEach((workload) => {
            workload.versions?.forEach((version) => {
              state.versionList.map((v) => {
                if (v._id === version.versionId) {
                  v.isDeployable = version.isVersionDeployable;
                }
                return v;
              });
            });
          });
      }
    },
  },
};
