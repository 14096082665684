/* eslint-disable no-case-declarations */
/* eslint-disable no-underscore-dangle */
/* eslint-disable default-case */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import Vue from 'vue';

import { NodesApiService, NodeTreeApiService, WorkloadsApiService, ConfigurationsApiService } from '@/services/api';
import NodeTreeModel, { TREE_NODE_TYPES } from '@/model/node-tree/node-tree.model';
import DeployedWorkloadModel, { STATS, PROPERTY_LIST_NAMES } from '@/model/node/sub-models/deployed-workload.model';
import DeployedComposeWorkloadModel from '@/model/node/sub-models/deployed-compose-workload.model';
import DeployedComposeWorkloadServicesModel from '@/model/node/sub-models/deployed-compose-workload-services.model';
import NodeModel from '@/model/node/node.model';
import NodeTreeHelper from '@/store/helpers/node-tree.helper';
import WorkloadsHelper from '@/store/helpers/workloads.helper';
import mqtt from '@/plugins/mqtt';
import shared from '@/helpers/shared';
import store from '@/store';
import router from '@/router';
import i18n from '@/i18n';
import { STATUSES, TYPE_OF_WORKLOADS } from '../../constants';

const START_EMITTING_TIMEOUT = 2000;

const getDefaultNodeTreeState = () => ({
  nodes: [],
  lastChangeNodesBackup: [],
  dialogState: {
    isShown: false,
    id: '',
    parentId: '',
  },
  selectedNode: new NodeModel(),
  selectedTreeNodes: [],
  selectedDeployedWorkload: new DeployedWorkloadModel({
    id: '',
    name: ' ',
    status: '',
    stats: {},
  }),
  isTreeExpanded: false,
  isLoading: false,
  vmResourcesResponse: {},
  areMethodCalled: false,
  dumpEvents: [],
  versionIdOfSelectedWl: '',
  cancelToken: {},
  cancelTokenStartSending: {},
  cancelTokenDnaStatus: {},
  isNodeDataAvailable: false,
  isNewComposeSupported: false,
  lastState: '',
  workloadTableSearchValue: '',
  queryParams: {},
});

export default {
  state: getDefaultNodeTreeState(),
  mutations: {
    SET_ROOT_NODE(state, nodes) {
      // preserve expanded nodes
      const expandedNodes = [];
      NodeTreeHelper.traverseNodeTree(state.nodes, (node) => {
        if (node.isExpanded) {
          expandedNodes.push(node);
        }
      });
      state.nodes = nodes.map((n) => new NodeTreeModel(n));
      // expand newly fetched nodes
      expandedNodes.forEach((n) => {
        const foundNode = NodeTreeHelper.findRecursively(state.nodes, 'id', n.data.id);
        if (foundNode) {
          foundNode.isExpanded = true;
        }
        return foundNode;
      });
      // fix order for newly set nodes
      NodeTreeHelper.traverseNodeTree(state.nodes, (node) => {
        if (node.children) {
          node.children.sort((a, b) => a.data.orderIndex - b.data.orderIndex);
        }
      });
      Vue.set(state, 'nodes', [...state.nodes]);
    },
    RESET_NODE_TREE_STATE(state) {
      state.nodes = [...state.nodes];
    },
    SET_NODE_CHILDREN(state, { parent, nodes, revertLastChange }) {
      const notIncludedChildren = nodes.filter(
        (child) => !parent.children.map((c) => c.data.name).includes(child.name),
      );

      // this fix is related to issue NERVESW-7977
      const newChildren = revertLastChange ? [...parent.children, ...notIncludedChildren] : [...nodes];
      parent.children = newChildren
        .map((n) => new NodeTreeModel(n))
        .sort((a, b) => a.data.orderIndex - b.data.orderIndex);

      Vue.set(state, 'nodes', [...state.nodes]);
    },
    TOGGLE_EXPAND(state, { node, isForced }) {
      const treeNodeFromState = NodeTreeHelper.findRecursively(state.nodes, 'id', node.data.id);
      Vue.set(treeNodeFromState, 'isExpanded', isForced || !treeNodeFromState.isExpanded);
      Vue.set(state, 'nodes', [...state.nodes]);
    },
    UPDATE_AFTER_DROP(state, { draggedNodes, parentNode }) {
      const destinationParent = NodeTreeHelper.findRecursively(state.nodes, 'id', parentNode.data.id);

      draggedNodes.forEach((c) => NodeTreeHelper.deleteRecursively(state.nodes, c.data.id));
      // parent already contains dropped children
      // - that is why we use this those to update and re-assign parent from
      // the tree preserved in state - in this case destinationParent
      destinationParent.children = parentNode.children.map((c) => NodeTreeHelper.updateChildAfterDrop(c, parentNode));

      Vue.set(state, 'nodes', [...state.nodes]);
    },
    REMOVE_NODE(state, node) {
      NodeTreeHelper.deleteRecursively(state.nodes, node.data.id);
      Vue.set(state, 'nodes', [...state.nodes]);
    },
    TOGGLE_ADD_EDIT_DIALOG(state, { isShown = false, id = '', parentId = '' }) {
      state.dialogState = {
        isShown,
        id,
        parentId,
      };
    },
    UPDATE_NODE(state, node) {
      Object.assign(node, new NodeTreeModel(node));
      Vue.set(state, 'nodes', [...state.nodes]);
    },
    SET_BACKUP_NODES(state, children) {
      state.lastChangeNodesBackup = children.map((c) => {
        c.isSelected = false;
        return new NodeTreeModel(c);
      });
    },
    UPDATE_BACKUP_NODE_STATUS(state, node) {
      state.lastChangeNodesBackup.forEach((backupNode) => {
        if (backupNode.data.device._id === node._id) {
          backupNode.data.device.connectionStatus = node.connectionStatus;
        }
        return backupNode;
      });
    },
    SET_SELECTED_NODE(state, device) {
      const treeNode = NodeTreeHelper.findRecursively(state.nodes, 'device.id', device.id || device._id);
      state.selectedNode = (treeNode && treeNode.data.device) || new NodeModel({});
      state.isNewComposeSupported =
        treeNode && treeNode.data && treeNode.data.device
          ? shared.isNoPrerelesedVersionGreaterThanOrEqualTo(treeNode.data.device.currentFWVersion, '2.7.0')
          : false;
    },
    SET_SELECTED_NODE_WITH_NODE_TREE(state, treeNode) {
      state.selectedNode = (treeNode && treeNode.data.device) || new NodeModel({});
      state.isNewComposeSupported =
        treeNode && treeNode.data && treeNode.data.device
          ? shared.isNoPrerelesedVersionGreaterThanOrEqualTo(treeNode.data.device.currentFWVersion, '2.7.0')
          : false;
    },
    SET_TREE_NODE_DEVICE(state, { device, statsInfo, setSelectedNode }) {
      NodeTreeHelper.traverseNodeTree(state.nodes, (node) => {
        node.isSelected = !!state.selectedTreeNodes.find((n) => n.title === node.title);
      });
      const stateTreeNode = device.id
        ? NodeTreeHelper.findRecursively(state.nodes, 'device.id', device.id || device._id)
        : NodeTreeHelper.findRecursively(state.nodes, 'device.serialNumber', device.serialNumber || device.serial);
      if (!stateTreeNode) {
        return;
      }
      const treeNodeDevice = stateTreeNode.data.device;
      if (setSelectedNode) {
        state.selectedNode = treeNodeDevice || new NodeModel({});
        state.isNewComposeSupported = treeNodeDevice
          ? shared.isNoPrerelesedVersionGreaterThanOrEqualTo(treeNodeDevice.currentFWVersion, '2.7.0')
          : false;
      }
      stateTreeNode.title = device.name || treeNodeDevice.name; // tree node title update
      stateTreeNode.isSelected = true;
      // we are doing assign because we don't get whole node on every device update
      if (statsInfo) {
        const workloadStats = statsInfo.workloads;
        delete statsInfo.workloads;
        Object.assign(
          treeNodeDevice,
          new NodeModel({
            ...treeNodeDevice,
            ...device,
            ...statsInfo,
            workloadStats,
          }),
        );
      } else {
        Object.assign(treeNodeDevice, new NodeModel({ ...treeNodeDevice, ...device }));
      }
      state.nodes = [...state.nodes];
    },

    SET_CPU_RAM_WORKLOAD_ON_SELECTED_NODE(state, { stats, deviceId }) {
      const device = state.selectedNode;
      const stateTreeNode = NodeTreeHelper.findRecursively(
        state.nodes,
        'device.serialNumber',
        device.serialNumber || device.serial,
      );
      if (!stateTreeNode) {
        return;
      }
      const treeNodeDevice = stateTreeNode.data.device;
      stateTreeNode.title = device.name || treeNodeDevice.name;
      const workloadStats = Object.assign(stats, { deviceId });
      Object.assign(
        treeNodeDevice,
        new NodeModel({
          ...treeNodeDevice,
          ...device,
          workloadStats: [workloadStats],
        }),
      );
    },

    SET_SELECTED_NODES(state, selectedTreeNodes) {
      state.selectedTreeNodes = selectedTreeNodes;
    },
    SET_TREE_NODE_DEVICE_WORKLOADS(state, { device, workloads }) {
      const stateTreeNode = NodeTreeHelper.findRecursively(
        state.nodes,
        'device.serialNumber',
        device.serialNumber || device.serial,
      );
      if (!stateTreeNode) {
        return;
      }
      const treeNodeDevice = stateTreeNode.data.device;
      treeNodeDevice.devices = NodeTreeHelper.addOrUpdateAndMergeDeployedWls(workloads, treeNodeDevice).map((d) =>
        (d._type === TYPE_OF_WORKLOADS.COMPOSE || d._type === 'Docker Compose') && state.isNewComposeSupported
          ? new DeployedComposeWorkloadModel(d)
          : new DeployedWorkloadModel(d),
      );
      // hack to prevent blinking
      // setTimeout(() => {
      if (!state.dumpEvents.length) {
        Object.assign(treeNodeDevice, new NodeModel(treeNodeDevice));
        NodeTreeHelper.setSelectedTreeNodes(state.nodes, state.selectedTreeNodes);
        state.nodes = [...state.nodes];
      }
      // }, 5000);
    },
    REMOVE_DEPLOYED_WORKLOAD(state, params) {
      const { devices } = state.selectedNode;
      const deviceIndex = devices.findIndex((d) => d.id === params.device_id);
      if (deviceIndex !== -1) {
        devices.splice(deviceIndex, 1);
      }
    },
    SET_SELECTED_DEPLOYED_WORKLOAD(state, deployedWorkload) {
      state.selectedDeployedWorkload = deployedWorkload;
    },
    SET_PROP_DEPLOYED_WORKLOAD(state, { propName, propValue }) {
      if (state.selectedDeployedWorkload) {
        state.selectedDeployedWorkload[propName] = propValue;
      }
    },
    SET_PROP_DEPLOYED_WORKLOAD_SERVICES(state, values) {
      const { services, configurationUpdateInfo } = values;
      if (state.selectedDeployedWorkload && services) {
        const newServices = [];
        state.selectedDeployedWorkload.services = state.selectedDeployedWorkload.services || [];
        services.forEach((service) => {
          let configurationInfo = {};
          let configurationStatus = {};
          if (configurationInfo) {
            if (Array.isArray(configurationUpdateInfo)) {
              // eslint-disable-next-line max-len
              configurationInfo = configurationUpdateInfo.find((config) => config.serviceName === service.serviceName);
              configurationStatus = {
                ...(configurationInfo && { timestamp: configurationInfo.timestamp }),
                ...(configurationInfo && { containerPath: configurationInfo.path }),
                ...(configurationInfo && { volumeName: configurationInfo.name }),
                ...(configurationInfo && { status: configurationInfo.status }),
                ...(configurationInfo && { user: configurationInfo.user }),
                ...(configurationInfo && { message: configurationInfo.message }),
              };
            } else {
              configurationInfo = {
                // eslint-disable-next-line max-len
                ...(configurationUpdateInfo && {
                  restartOnConfigurationUpdate: configurationUpdateInfo.restartOnConfigurationUpdate,
                }),
                ...(configurationUpdateInfo &&
                  configurationUpdateInfo.configurationVolumes && {
                    path: configurationUpdateInfo.configurationVolumes[0].path,
                  }),
                ...(configurationUpdateInfo &&
                  configurationUpdateInfo.configurationVolumes && {
                    name: configurationUpdateInfo.configurationVolumes[0].name,
                  }),
                serviceName: service.serviceName,
              };
              configurationStatus = {
                ...(configurationInfo && { timestamp: configurationInfo.timestamp }),
                ...(configurationInfo && { containerPath: configurationInfo.path }),
                ...(configurationInfo && { volumeName: configurationInfo.name }),
                ...(configurationInfo && { status: configurationInfo.status }),
                ...(configurationInfo && { user: configurationInfo.user }),
                ...(configurationInfo && { message: configurationInfo.message }),
              };
            }
          }
          service = {
            ...service,
            configurationUpdateInfo: configurationInfo,
            configurationUpdateStatus: configurationStatus,
          };
          newServices.push(
            new DeployedComposeWorkloadServicesModel({
              ...state.selectedDeployedWorkload.services.find((s) => s.serviceName === service.serviceName),
              ...service,
            }),
          );
        });
        Vue.set(state.selectedDeployedWorkload, 'services', newServices);
      }
    },
    SET_WL_STATS_FOR_OLD_NODE_VERSIONS(state, stats) {
      // we will receive device_property_changed for selected and not selected workloads
      // if something is not clear about this contact Drazen Kvrgic
      if (state.selectedDeployedWorkload && state.selectedDeployedWorkload.id === stats.deviceId) {
        Object.entries(stats).forEach(([key, value]) => {
          state.selectedDeployedWorkload.stats[key] = value;
        });
      }
    },
    SET_PROP_DEPLOYED_WORKLOAD_STATS(state, stats) {
      if (state.selectedDeployedWorkload) {
        Object.entries(stats).forEach(([key, value]) => {
          state.selectedDeployedWorkload.stats[key] = value;
        });
      }
    },
    SET_PROP_DEPLOYED_COMPOSE_WORKLOAD(state, { propName, values }) {
      if (state.selectedDeployedWorkload) {
        if (propName === 'services' && values.services) {
          values.services.forEach((serviceWithStatus) => {
            state.selectedDeployedWorkload.services.forEach((service, index) => {
              if (service.containerName === serviceWithStatus.containerName && serviceWithStatus.state) {
                Vue.set(
                  state.selectedDeployedWorkload.services[index],
                  'status',
                  shared.getKeyByValue(STATUSES, serviceWithStatus.state).toLowerCase(),
                );
              }
              if (service.containerName === serviceWithStatus.containerName && serviceWithStatus.timestamp) {
                Vue.set(
                  state.selectedDeployedWorkload.services[index],
                  'lastStateChange',
                  new Date(serviceWithStatus.timestamp).toLocaleString('en-GB'),
                );
              }
              return service;
            });
          });
        }
        if (propName === 'configurationUpdateStatus' && state.selectedDeployedWorkload.services) {
          state.selectedDeployedWorkload.services.map((service) => {
            if (service.serviceName === values.serviceName) {
              service.configurationUpdateStatus = {
                timestamp: values.timestamp,
                containerPath: values.containerPath,
                volumeName: values.volumeName,
                status: values.status,
                user: values.user,
                message: values.message,
              };
            }
            return service;
          });
        }
      }
    },
    SET_IS_TREE_EXPANDED(state, isTreeExpanded) {
      state.isTreeExpanded = isTreeExpanded;
    },
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_IS_EXPANDED_FLAG_TO_ALL_NODES(state, shouldExpandNode) {
      NodeTreeHelper.traverseNodeTree(state.nodes, (node) => {
        if (node.isTypeOf(TREE_NODE_TYPES.ROOT)) {
          node.isExpanded = true;
          return;
        }
        if (!node.isTypeOf(TREE_NODE_TYPES.NODE)) {
          node.isExpanded = shouldExpandNode;
        }
      });
    },
    async SET_IS_SELECTED_FLAG_TO_FALSE_TO_ALL_NODES(state) {
      await NodeTreeHelper.traverseNodeTree(state.nodes, (node) => {
        if (node.isTypeOf(TREE_NODE_TYPES.NODE)) {
          node.isSelected = false;
        }
      });
    },
    DISPLAY_FOUND_NODES(state, { foundNodes, highlightWithoutTimeout }) {
      // eslint-disable-next-line max-len
      foundNodes.forEach((node) =>
        NodeTreeHelper.handleFoundNodeBySearch({ stateNodes: state.nodes, node, highlightWithoutTimeout }),
      );
    },
    SET_RESOURCES_PROP_DEPLOYED_WORKLOAD(state, { fieldName, fieldValue }) {
      if (state.selectedDeployedWorkload) {
        state.selectedDeployedWorkload.resources[fieldName] = fieldValue;
      }
    },
    SET_RESOURCES_DEPLOYED_WORKLOAD(state, { resources }) {
      if (state.selectedDeployedWorkload) {
        state.selectedDeployedWorkload._resources = resources;
      }
    },
    SET_RESPONSE: (state, params) => {
      state.vmResourcesResponse = { ...params };
    },
    SET_CALLED_METHOD: (state, status) => {
      state.areMethodCalled = status;
    },
    SET_DUMP_EVENTS: (state, event) => {
      state.dumpEvents.push(event);
    },
    CLEAR_DUMP_EVENTS: (state) => {
      state.dumpEvents = [];
    },
    SET_VERSION_ID_OF_SELECTED_WL: (state, versionId) => {
      state.versionIdOfSelectedWl = versionId;
    },
    SET_CANCEL_TOKEN: (state, cancelToken) => {
      state.cancelToken = cancelToken;
    },
    SET_CANCEL_TOKEN_START_SENDING: (state, cancelToken) => {
      state.cancelTokenStartSending = cancelToken;
    },
    SET_CANCEL_TOKEN_DNA_STATUS: (state, cancelToken) => {
      state.cancelTokenDnaStatus = cancelToken;
    },
    SET_IS_NODE_DATA_AVAILABLE: (state, isNodeDataAvailable) => {
      state.isNodeDataAvailable = isNodeDataAvailable;
    },
    SET_IS_NEW_COMPOSE_SUPPORTED: (state, value) => {
      state.isNewComposeSupported = value;
    },
    RESET_STATE_TO_DEFAULT_VALUES: (state) => {
      Object.assign(state, getDefaultNodeTreeState());
    },
    SET_LAST_STATE: (state, lastState) => {
      state.lastState = lastState;
    },
    SET_RC_APPROVAL_SELECTED_NODE: (state, approve) => {
      state.selectedNode.rcApproval = approve || 0;
    },
    SET_WORKLOAD_TABLE_SEARCH_VALUE: (state, value) => {
      state.workloadTableSearchValue = value;
    },
    SET_QUERY_PARAMS: (state, params) => {
      state.queryParams = params;
    },
  },
  getters: {
    getNodes: (state) => state.nodes,
    getDialogState: (state) => state.dialogState,
    getNodeByType: (state) => (type) => NodeTreeHelper.findRecursively(state.nodes, 'type', type),
    getNodeById: (state) => (id) => NodeTreeHelper.findRecursively(state.nodes, 'id', id),
    getLastChangeNodesBackup: (state) => state.lastChangeNodesBackup || [],
    getSelectedNode: (state) => state.selectedNode,
    getSelectedDeployedWorkload: (state) => state.selectedDeployedWorkload,
    getIsTreeExpanded: (state) => state.isTreeExpanded,
    getIsLoading: (state) => state.isLoading,
    getCalledMethod: (state) => state.areMethodCalled,
    getDumpEvents: (state) => state.dumpEvents,
    getVersionIdOfSelectedWl: (state) => state.versionIdOfSelectedWl,
    isNodeDataAvailable: (state) => state.isNodeDataAvailable,
    getIsNewComposeSupported: (state) => state.isNewComposeSupported,
    getCancelTokenDnaStatus: (state) => state.cancelTokenDnaStatus,
    getTableSearchValue: (state) => state.workloadTableSearchValue,
    getQueryParams: (state) => state.queryParams,
  },
  actions: {
    /**
     * @description Reset state of the node tree to discard changes made in
     * tree library state
     * @param commit
     */
    reset_state({ commit }) {
      commit('RESET_NODE_TREE_STATE');
    },
    /**
     * @description Fetch root node initially
     * @param commit
     * @returns {Promise<*>}
     */
    // eslint-disable-next-line consistent-return
    async get_root_node({ commit }) {
      return commit('SET_ROOT_NODE', await NodeTreeApiService.getByType(TREE_NODE_TYPES.ROOT));
    },
    /**
     * @description Fetch nodes children by the parent type
     * @param commit
     * @param state
     * @param parent - Tree library provided object
     * @returns {Promise<*>}
     */
    async get_children_by_type({ commit, state }, parent) {
      const treeNodeFromState = NodeTreeHelper.findRecursively(state.nodes, 'id', parent.data.id);
      const node = await NodeTreeApiService.getChildrenByType(parent.data.type);
      commit('SET_NODE_CHILDREN', {
        nodes: NodeTreeHelper.setOrderIndexTo(node),
        parent: treeNodeFromState,
      });
      return node;
    },
    /**
     * @description Fetch children by parent id from the api
     * @param commit
     * @param state
     * @param parent - TreeNode parent
     * @returns {Promise<*>}
     */
    // eslint-disable-next-line consistent-return
    async get_children_by_parent_id({ commit, state }, parent) {
      const node = await NodeTreeApiService.getByParentId(parent.data.id);
      commit('SET_NODE_CHILDREN', {
        nodes: node,
        parent: NodeTreeHelper.findRecursively(state.nodes, 'id', parent.data.id),
      });
      return node;
    },
    /**
     * @description Expand clicked node
     * @param commit
     * @param node - selected node
     * @param isForced - force expand
     */
    toggle_expand({ commit }, { node, isForced }) {
      commit('TOGGLE_EXPAND', { node, isForced });
    },
    /**
     * @description Update state after drag and drop of nodes and send updated nodes to the api
     * @param commit
     * @param getters
     * @param draggedNodes - Collection of library provided nodes
     * @param parentNode - parent or the destination node
     */
    handle_dropped_node({ commit, getters }, { draggedNodes, parentNode }) {
      commit('SET_BACKUP_NODES', NodeTreeHelper.shouldBeBackuped(draggedNodes, parentNode) ? draggedNodes : []);
      commit('UPDATE_AFTER_DROP', { parentNode, draggedNodes });

      const destinationParentAfterUpdate = NodeTreeHelper.findRecursively(getters.getNodes, 'id', parentNode.data.id);
      const updatedChildren = destinationParentAfterUpdate.children.map((c) => c.prepareForApi());
      return NodeTreeApiService.upsertMany({ treeNodes: updatedChildren });
    },
    /**
     * @description Remove tree node and all of its children, move nodes to unassigned folder by
     * updating unassigned folder, also reset backup of nodes
     * @param commit
     * @param getters
     * @param dispatch
     * @param node - Node to be deleted
     * @returns {Promise<void>}
     */
    async remove({ commit, dispatch }, node) {
      await NodeTreeApiService.deleteTreeNodesRecursively(node.data.id);
      commit('REMOVE_NODE', node);
      commit('SET_BACKUP_NODES', []);
      await dispatch('update_unassigned');
    },
    /**
     * @description Re-fetch unassigned children if the tree node isExpanded
     * @param dispatch
     * @param getters
     * @returns {Promise<void>}
     */
    async update_unassigned({ dispatch, getters }) {
      const unassignedNode = getters.getNodeByType(TREE_NODE_TYPES.UNASSIGNED);
      if (!unassignedNode || !unassignedNode.isExpanded) {
        return;
      }
      await dispatch('get_children_by_type', unassignedNode);
      dispatch('toggle_expand', { node: unassignedNode, isForced: true });
    },
    /**
     * @description Remove type node, clear selected node, clear backup nodes
     * @param commit
     * @param getters
     * @param dispatch
     * @param node - Node to be removed
     * @returns {Promise<void>}
     */
    async remove_type_node({ commit, getters }, node) {
      await NodesApiService.remove(node.serialNumber);
      if (node.connectionStatus === 'online') {
        NodeTreeHelper.clearExchangeInterval();
        mqtt.unsubscribeFrom('node', node);
      }
      const treeNode = NodeTreeHelper.findRecursively(getters.getNodes, 'device.id', node.id);

      if (!treeNode) {
        return;
      }

      if (getters.getSelectedNode.id === node.id) {
        commit('SET_SELECTED_NODE', new NodeModel({}));
      }
      commit('REMOVE_NODE', treeNode);
      commit('SET_BACKUP_NODES', []);
    },
    /**
     * @description Toggle addEdit dialog
     * @param commit
     * @param value
     */
    toggle_add_edit_dialog({ commit }, value) {
      commit('TOGGLE_ADD_EDIT_DIALOG', value);
    },
    /**
     * @description Update type node
     * @param commit
     * @param updatedNode - NodeModel object that contains updated fields
     */
    update_type_node({ commit }, updatedNode) {
      commit('SET_TREE_NODE_DEVICE', { device: updatedNode });
    },
    /**
     * @description Update tree node and send to the api
     * @param commit
     * @param treeNode - NodeTreeModel object that contains updated fields
     * @returns {Promise<void>}
     */
    async update({ commit }, treeNode) {
      await NodeTreeApiService.upsertMany({ treeNodes: [treeNode.prepareForApi()] });
      commit('UPDATE_NODE', treeNode);
    },
    /**
     * @description Create node type folder, fetch mocked node (not preserved yet), update in state,
     * assign orderIndex, save to api and fetch updated parent
     * @param commit
     * @param getters
     * @param dispatch
     * @param treeNode - Node that is created (contains name)
     * @returns {Promise<void>}
     */
    async create({ commit, getters, dispatch }, treeNode) {
      const mockedNode = await NodeTreeApiService.mock(new NodeTreeModel(treeNode).prepareForApi());
      const parentNode = NodeTreeHelper.findRecursively(getters.getNodes, 'id', mockedNode.parentId);
      mockedNode.orderIndex = (parentNode.children && parentNode.children.length) || 0;
      await NodeTreeApiService.upsertMany({ treeNodes: [mockedNode] });
      await dispatch('get_children_by_parent_id', parentNode);
      await dispatch('toggle_expand', { node: parentNode, isForced: true });
      commit('SET_BACKUP_NODES', []);
    },
    /**
     * @description Revert latest changes made by drag and drop,
     * each backed up node (from previous drop) is iterated and change is reverted
     * if backup parent is unassigned, all nodes must be deleted from the database
     * @param commit
     * @param getters
     * @param dispatch
     * @returns {Promise<void>}
     */
    async revert_latest_change({ commit, getters }) {
      const lastChangeNodesBackup = getters.getLastChangeNodesBackup;
      const nodes = getters.getNodes;
      try {
        lastChangeNodesBackup.forEach((n) => {
          const nodeFromState = NodeTreeHelper.findRecursively(nodes, 'id', n.data.id);
          const backupNodeParent =
            NodeTreeHelper.findRecursively(nodes, 'id', n.data.parentId) ||
            getters.getNodeByType(TREE_NODE_TYPES.UNASSIGNED);
          if (!nodeFromState) {
            commit('SET_BACKUP_NODES', []);
            store.dispatch('utils/_api_request_handler/show_custom_toast', {
              text: 'nodes.tree.nodeMissing',
              color: 'red',
              showClose: true,
            });
            throw Error('Node missing!');
          }
          commit('REMOVE_NODE', nodeFromState);
          commit('SET_NODE_CHILDREN', {
            parent: backupNodeParent,
            nodes: [n],
            revertLastChange: true,
          });
          if (backupNodeParent.isTypeOf(TREE_NODE_TYPES.UNASSIGNED)) {
            NodeTreeApiService.deleteTreeNodesRecursively(n.data.id);
          }
        });
        commit('SET_BACKUP_NODES', []);
        await NodeTreeApiService.upsertMany({
          treeNodes: lastChangeNodesBackup.map((n) => new NodeTreeModel(n).prepareForApi()),
        });
      } catch (e) {
        Vue.prototype.$log.debug('node-tree:revert_latest_change:', e.message || e);
      }
    },
    /**
     * @description subscribe to new device, unsubscribe from old device,
     * set tree node and selected node
     * invoke mqtt events for node status bars and deployed workloads
     * send api request for additional information (wan address etc.)
     * @param commit
     * @param getters
     * @param dispatch
     * @param treeNode - selected treeNode
     * @returns {Promise<void>}
     */
    async select_node({ commit, getters, state }, { treeNode, workloadControlPermission }) {
      if (!treeNode) {
        return;
      }
      const newSelectedDevice = new NodeModel(treeNode.data.device);
      const oldSelectedDevice = getters.getSelectedNode;
      if (oldSelectedDevice && newSelectedDevice.id === oldSelectedDevice.id) {
        await NodeTreeHelper.cancelPendingRequests(state.cancelToken);
        await NodeTreeHelper.cancelPendingDnaStatusRequests(state.cancelTokenDnaStatus);
        const dna = {
          getDnaStatus: true,
          isDnaSupported: shared.isNoPrerelesedVersionGreaterThanOrEqualTo(newSelectedDevice.currentFWVersion, '2.6.0'),
        };
        NodeTreeHelper.fetchNodeDetails(newSelectedDevice, workloadControlPermission, dna).then((apiProvidedDevice) => {
          if (
            apiProvidedDevice.serialNumber === newSelectedDevice.serialNumber &&
            apiProvidedDevice.serialNumber === state.selectedNode.serialNumber
          ) {
            commit('SET_TREE_NODE_DEVICE', { device: apiProvidedDevice, setSelectedNode: true });
          }
        });
        return;
      }
      commit('SET_WORKLOAD_TABLE_SEARCH_VALUE', '');
      NodeTreeHelper.setStartEmittingFlag(false);
      await mqtt.unsubscribeFrom('node', oldSelectedDevice);
      await mqtt.subscribeTo('node', newSelectedDevice);
      commit('SET_TREE_NODE_DEVICE', { device: newSelectedDevice, setSelectedNode: true });
      await NodeTreeHelper.cancelPendingRequests(state.cancelToken);
      await NodeTreeHelper.cancelPendingDnaStatusRequests(state.cancelTokenDnaStatus);
      NodeTreeHelper.fetchNodeDetails(newSelectedDevice, workloadControlPermission)
        .then(async (apiProvidedDevice) => {
          if (state.cancelTokenStartSending.cancel) {
            state.cancelTokenStartSending.cancel(i18n.t('nodes.tree.cancelRequestMessage.startSending'));
          }
          if (
            apiProvidedDevice.serialNumber !== newSelectedDevice.serialNumber ||
            apiProvidedDevice.serialNumber !== state.selectedNode.serialNumber
          ) {
            return;
          }
          commit('SET_TREE_NODE_DEVICE', {
            device: apiProvidedDevice,
            setSelectedNode: true,
          });
          commit('SET_IS_NODE_DATA_AVAILABLE', true);
          if (!apiProvidedDevice.cancelOcurred) {
            await NodeTreeHelper.startEmitting({ newSelectedDevice });
          }
          NodeTreeHelper.setSelectedNode(newSelectedDevice);
        })
        .catch((error) => {
          Vue.prototype.$log.debug(error);
        });
    },
    async select_deployed_workload({ state, commit, getters, dispatch }, id) {
      const node = getters.getSelectedNode;
      const workload = node.devices.find((device) => device.id.toString() === id.toString());
      if (workload) {
        const { values } = await NodesApiService.getDataFromNode({
          dataId: 'workload',
          serialNumber: node.serialNumber,
          data: workload.id,
        });
        WorkloadsHelper.setStartEmittingFlag(false);
        commit('SET_SELECTED_DEPLOYED_WORKLOAD', workload);
        commit('SET_PROP_DEPLOYED_WORKLOAD', {
          propName: 'timestamp',
          propValue: values.statusChangedTimestamp,
        });
        if (workload.type !== TYPE_OF_WORKLOADS.COMPOSE || !state.isNewComposeSupported) {
          commit('SET_PROP_DEPLOYED_WORKLOAD', {
            propName: 'configurationUpdateInfo',
            propValue: values.configurationUpdateInfo,
          });
        }
        if (
          workload.type === TYPE_OF_WORKLOADS.DOCKER ||
          (workload.type === TYPE_OF_WORKLOADS.COMPOSE && !state.isNewComposeSupported)
        ) {
          dispatch('parseAndSetEnvVarsAndPorts', { envVars: values.envVars, ports: values.ports });
        }
        if (workload.type === TYPE_OF_WORKLOADS.COMPOSE && state.isNewComposeSupported) {
          commit('SET_PROP_DEPLOYED_WORKLOAD_SERVICES', {
            services: values.servicesInformation,
            configurationUpdateInfo: values.configurationUpdateInfo,
          });
        }
        if (workload.type === TYPE_OF_WORKLOADS.VM) {
          const resources = {
            memory: values.resources?.memory?.current || 0,
            cpu: values.resources?.cpu?.current || 0,
          };
          commit('SET_RESOURCES_DEPLOYED_WORKLOAD', { resources });
        }
        const isVersionSatisfying = shared.isNoPrerelesedVersionGreaterThanOrEqualTo(node.currentFWVersion, '2.5.0');
        if (isVersionSatisfying && workload.type !== TYPE_OF_WORKLOADS.CODESYS) {
          WorkloadsHelper.setSelectedWorkload(workload);
          WorkloadsHelper.setSelectedNode(node);
        }
      }
    },
    async set_selected_deployed_workload({ commit }, workload) {
      commit('SET_SELECTED_DEPLOYED_WORKLOAD', workload);
    },
    /**
     * @description Dedicated action for mqtt oblo/all/admin/svc/notifier/evt topic
     * @param commit
     * @param getters
     * @param dispatch
     * @param payload - mqtt message payload
     */
    mqtt_selected_node_updated({ commit, getters, dispatch }, payload) {
      switch (payload.name) {
        case 'NodePersistentDataEvent':
        case 'NodeDataEvent':
          const deployedWorkload = getters.getSelectedDeployedWorkload;
          // eslint-disable-next-line default-case
          switch (payload.params.dataId) {
            case 'workload':
              commit('SET_TREE_NODE_DEVICE_WORKLOADS', {
                device: { serial: payload.params.serial },
                workloads: [payload.params.values.workload],
              });
              // eslint-disable-next-line no-case-declarations
              if (deployedWorkload && deployedWorkload.id === payload.params.values.workload.deviceId) {
                commit('SET_PROP_DEPLOYED_WORKLOAD', {
                  propName: 'timestamp',
                  propValue: payload.params.values.workload.statusChangedTimestamp,
                });
              }
              break;
            case 'wan_ip_address':
            case 'system_stats':
              commit('SET_TREE_NODE_DEVICE', {
                device: { serial: payload.params.serial },
                statsInfo: payload.params.values,
              });
              break;
            case 'monitoring_and_logging_status':
              // the status is only updated for the selected node
              if (payload.params.serial === getters.getSelectedNode.serialNumber) {
                dispatch('nodes/update_monitoring_and_logging_params', payload, { root: true });
              }
              break;

            case `${deployedWorkload.workloadId}_${deployedWorkload.versionId}_stats`:
              Object.entries(payload.params.values).forEach(([key, value]) => {
                const statsObj = {};
                statsObj[key] = value;
                commit('SET_PROP_DEPLOYED_WORKLOAD_STATS', statsObj);
              });
              break;
            case `${deployedWorkload.workloadId}_${deployedWorkload.versionId}_all_services_status`:
              commit('SET_PROP_DEPLOYED_COMPOSE_WORKLOAD', {
                propName: 'services',
                values: payload.params.values,
              });
              break;
            case 'RemoteConnectionSettings':
              commit('SET_RC_APPROVAL_SELECTED_NODE', payload?.params?.values?.approve);
              break;
          }
          break;
        case 'StatusOfChangingVmResource':
          commit('SET_RESPONSE', payload.params);
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: payload.params.message,
            color: payload.params.status === 'SUCCESS' ? 'success' : 'error',
            showClose: true,
          });
          break;
      }
    },
    /**
     * @description Dedicated action for mqtt oblo/+/gtw/+/ovdm/evt
     * node is subscribed for this events in select_node action
     * if selected node is updated trough mqtt
     * invoke mqtt events and fetch additional data
     * @param commit
     * @param getters
     * @param dispatch
     * @param payload
     * @returns {Promise<void>}
     */
    async mqtt_any_node_updated({ commit, getters, dispatch }, payload) {
      switch (payload.name) {
        case 'crud-node':
          await dispatch('get_full_tree');
          break;
        case 'all_admins:udm_device_updated':
        case 'all_admins:udm-device-updated':
          commit('SET_DUMP_EVENTS', payload.params.data);
          commit('SET_CALLED_METHOD', true);
          const dumpEvents = getters.getDumpEvents;
          const device = new NodeModel(dumpEvents[dumpEvents.length - 1]);
          const dev = new NodeModel(payload.params.data);
          dispatch('nodes/mqtt_update_node', dev, { root: true });
          commit('UPDATE_BACKUP_NODE_STATUS', dev);
          if (payload.params.data._id !== getters.getSelectedNode.id) {
            await commit('SET_TREE_NODE_DEVICE', { device: new NodeModel(payload.params.data) });
            if (!getters.getSelectedNode.id) {
              commit('CLEAR_DUMP_EVENTS');
            }
            commit('SET_CALLED_METHOD', false);
            return;
          }
          NodeTreeHelper.setStartEmittingFlag(false);
          const isNodeOnline = dumpEvents[dumpEvents.length - 1].connectionStatus !== 'offline';
          if (isNodeOnline) {
            // waiting appropriate user to subscribe on mqtt broker
            setTimeout(async () => {
              await NodeTreeHelper.startEmitting({ newSelectedDevice: device });
            }, START_EMITTING_TIMEOUT);
          }
          NodeTreeHelper.setSelectedNode(device);
          NodeTreeHelper.fetchNodeDetails(device, isNodeOnline).then((apiProvidedDevice) => {
            commit('SET_TREE_NODE_DEVICE', { device: apiProvidedDevice, setSelectedNode: true });
            commit('SET_CALLED_METHOD', false);
            commit('CLEAR_DUMP_EVENTS');
          });
      }
    },
    /**
     * @description Dedicated action for `oblo/${userId}/gtw/${serialNumber}/ohm/evt`
     * @param commit
     * @param getters
     * @param payload
     */
    mqtt_selected_node_deployed_wl_updated({ state, commit, getters }, payload) {
      // description Dedicated action for `oblo/${userId}/gtw/${serialNumber}/ovdm/evt`
      switch (payload.name) {
        case 'device_property_changed':
          // eslint-disable-next-line no-case-declarations
          const stats = shared.getKeyByValue(STATS, payload.params.property_name);
          if (stats) {
            const statsName = stats.toLowerCase();
            const key = statsName;
            const statsObj = {};
            statsObj[key] = payload.params.property_value;
            // to update cpu and ram of the wl in the table view
            commit('SET_CPU_RAM_WORKLOAD_ON_SELECTED_NODE', {
              stats: statsObj,
              deviceId: payload.params.device_id,
            });
            // for the older nodes to update stats property in the wl model
            // this property shows cpu and ram when it is clicked on the wl
            commit('SET_WL_STATS_FOR_OLD_NODE_VERSIONS', statsObj);
          }
          // eslint-disable-next-line no-case-declarations
          const selectedDeployWorkload = getters.getSelectedDeployedWorkload;
          if (payload.params.device_id !== selectedDeployWorkload.id) {
            return;
          }

          if (payload.params.property_name === PROPERTY_LIST_NAMES.MESSAGE) {
            commit('SET_PROP_DEPLOYED_WORKLOAD', {
              propName: 'message',
              propValue: { message: payload.params.property_value },
            });
          }
          if (payload.params.property_name === PROPERTY_LIST_NAMES.STATE) {
            const currentStatus = shared.getKeyByValue(STATUSES, payload.params.property_value).toLowerCase();
            commit('SET_PROP_DEPLOYED_WORKLOAD', {
              propName: 'currentStatus',
              propValue: { currentStatus },
            });
          }

          if (payload.params.property_name === PROPERTY_LIST_NAMES.CONFIGURATION_UPDATE_STATUS) {
            if (selectedDeployWorkload.type !== TYPE_OF_WORKLOADS.COMPOSE || !state.isNewComposeSupported) {
              commit('SET_PROP_DEPLOYED_WORKLOAD', {
                propName: 'configurationUpdateStatus',
                propValue: { configurationUpdateStatus: payload.params.property_value },
              });
            } else {
              commit('SET_PROP_DEPLOYED_COMPOSE_WORKLOAD', {
                propName: 'configurationUpdateStatus',
                values: payload.params.property_value,
              });
            }
          }

          if (payload.params.property_name === PROPERTY_LIST_NAMES.VIDEO_OUTPUT) {
            commit('SET_PROP_DEPLOYED_WORKLOAD', {
              propName: 'videoOutput',
              propValue: { videoOutput: payload.params.property_value },
            });
          }

          if (payload.params.property_name === PROPERTY_LIST_NAMES.MEMORY) {
            commit('SET_RESOURCES_PROP_DEPLOYED_WORKLOAD', {
              fieldName: 'memory',
              fieldValue: payload.params.property_value,
            });
          }

          if (payload.params.property_name === PROPERTY_LIST_NAMES.CPU_NUMBER) {
            commit('SET_RESOURCES_PROP_DEPLOYED_WORKLOAD', {
              fieldName: 'cpu',
              fieldValue: payload.params.property_value,
            });
          }
          break;
        case 'EVENT_DEVICE_REMOVED':
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'nodes.deployedWorkloadControl.undeployedSuccessfully',
            color: 'success',
            showClose: true,
          });
          commit('REMOVE_DEPLOYED_WORKLOAD', payload.params);
          if (WorkloadsHelper.selectedWorkload && WorkloadsHelper.selectedWorkload.id === payload.params.device_id) {
            WorkloadsHelper.clearExchangeInterval();
            if (
              window.location.pathname.includes('/device') &&
              window.location.pathname.includes(payload.params.device_id)
            ) {
              router.go(-1);
            }
          }
          break;
      }
    },
    async mqtt_handle_node_response(_, payload) {
      const { sessionId } = JSON.parse(localStorage.getItem('session'));
      // only user that initiated action (create workload) will receive a messages
      if (!payload.params.success && sessionId === payload.params.sessionId) {
        if (payload.type === TYPE_OF_WORKLOADS.VM) {
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'errorMessages.WORKLOAD_VM_IMG_CONVERSION_ERROR',
            color: 'red',
            showClose: true,
          });
        } else if (
          payload.params.type === TYPE_OF_WORKLOADS.DOCKER &&
          payload.params.error !== 'nerve_workload_cancelled'
        ) {
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: payload.params.errorCode
              ? `errorMessages.${payload.params.errorCode}`
              : 'errorMessages.WORKLOAD_DOCKER_IMG_DOWNLOAD_ERROR',
            color: 'red',
            showClose: true,
          });
        }
      } else if (payload.params.type === TYPE_OF_WORKLOADS.VM && sessionId === payload.params.sessionId) {
        store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'errorMessages.WORKLOAD_VM_IMG_CONVERTED_SUCCESSFULLY',
          color: 'green',
          showClose: true,
        });
      }
      if (payload.params.type === TYPE_OF_WORKLOADS.COMPOSE) {
        await store.dispatch('workloads/set_compose_version_status', payload.params);
        return;
      }
      if (payload.params.error) {
        await store.dispatch('workloads/update_workload_error_messages', payload.params);
      } else {
        await store.dispatch('workloads/update_workload', payload.params);
      }
      // only user that initiated action (create workload) will receive a messages
      if (payload.params.code === 1 && payload.params.params && payload.params.params.message) {
        store.dispatch('node-tree/revert_state');
        if (sessionId !== payload.params.sessionId) {
          return;
        }
        if (
          payload.params.params.message === 'WORKLOAD_BACKUP_IS_IN_PROGRESS_AND_THEREFORE_WORKLOAD_CANNOT_BE_STARTED'
        ) {
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'errorMessages.VM_BACKUP_IS_IN_PROGRESS_CANNOT_BE_STARTED',
            color: 'red',
            showClose: true,
          });
        } else {
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: payload.params.params.message,
            color: 'red',
            showClose: true,
          });
        }
      }
    },
    /**
     * @description Dedicated action for preserving selected node trough set method of the
     * computed property that uses getNodes getter
     * @param commit
     * @param selectedTreeNodes
     */
    preserve_selected({ commit }, selectedTreeNodes) {
      commit('SET_SELECTED_NODES', selectedTreeNodes);
    },
    /**
     * @description Clear exchange interval - eg. on destroyed component
     */
    clear_exchange_interval() {
      NodeTreeHelper.clearExchangeInterval();
    },
    /**
     * @description Restart mqtt events - eg. on mount of a component
     * @param commit
     * @param selectedNode
     * @returns {Promise<void>}
     */
    async restart_mqtt_events({ commit }, selectedNode) {
      if (!selectedNode.id || !selectedNode.isOnline()) {
        return;
      }

      NodeTreeHelper.setStartEmittingFlag(false);
      await mqtt.subscribeTo('node', selectedNode);
      await NodeTreeHelper.startEmitting({ newSelectedDevice: selectedNode });
      NodeTreeHelper.setSelectedNode(selectedNode);

      NodeTreeHelper.fetchNodeDetails(selectedNode).then((apiProvidedDevice) => {
        commit('SET_TREE_NODE_DEVICE', { device: apiProvidedDevice });
        commit('SET_SELECTED_NODE', apiProvidedDevice);
      });
    },

    async set_command({ commit }, { params, command }) {
      const currentStatus = shared.getKeyByValue(STATUSES, command.updateTo);
      commit('SET_PROP_DEPLOYED_WORKLOAD', {
        propName: 'currentStatus',
        propValue: { currentStatus },
      });
      await WorkloadsApiService.setCommand(params);
    },

    async revert_state({ state, commit }) {
      if (state.lastState) {
        commit('SET_PROP_DEPLOYED_WORKLOAD', {
          propName: 'currentStatus',
          propValue: { currentStatus: state.lastState },
        });
      }
    },
    set_last_state({ commit }, lastState) {
      commit('SET_LAST_STATE', lastState);
    },

    async apply_workload_configuration({ getters }, formData) {
      const node = getters.getSelectedNode;
      const selectedDeployWorkload = getters.getSelectedDeployedWorkload;
      await ConfigurationsApiService.applyWorkloadConfiguration(node.serialNumber, selectedDeployWorkload.id, formData);
    },
    /**
     * Get all nodes prepared from the api
     * @param commit
     * @returns {Promise<void>}
     */
    async get_full_tree({ commit }) {
      try {
        commit('SET_IS_LOADING', true);
        commit('SET_ROOT_NODE', await NodeTreeApiService.getFullTree());
      } catch (e) {
        Vue.prototype.$log.debug('node-tree:get_full_tree', e.message || e);
        throw e;
      } finally {
        commit('SET_IS_LOADING', false);
      }
    },
    /**
     * Get full tree, and then perform search by traversing all
     * nodes and then highlight found nodes
     * @param commit
     * @param getters
     * @param dispatch
     * @param searchTerm
     * @returns {*}
     */
    async search_node_tree({ commit, getters, dispatch }, payload) {
      let foundNodes = [];
      try {
        const { searchTerm, fullTreeAlreadyExists, highlightWithoutTimeout } = payload;
        let { searchParam } = payload;
        if (!searchTerm) {
          return [];
        }
        if (!searchParam) {
          searchParam = 'name';
        }
        if (!fullTreeAlreadyExists) {
          await dispatch('get_full_tree');
        }
        // eslint-disable-next-line max-len
        foundNodes = NodeTreeHelper.searchNodesRecursively(getters.getNodes, searchParam, searchTerm);
        if (!foundNodes.length) {
          // eslint-disable-next-line consistent-return
          dispatch(
            'utils/_api_request_handler/show_custom_toast',
            {
              text: 'nodes.tree.noMatchingNodes',
              color: 'warning',
              showClose: true,
            },
            { root: true },
          );
          return [];
        }
        commit('DISPLAY_FOUND_NODES', { foundNodes, highlightWithoutTimeout });
        commit('RESET_NODE_TREE_STATE');
      } catch (e) {
        Vue.prototype.$log.debug(e);
      }
      return foundNodes;
    },
    /**
     * Get full tree, set isExpanded to all of the nodes
     * @param commit
     * @param getters
     * @param dispatch
     * @returns {Promise<void>}
     */
    async expand_or_collapse_all_nodes({ commit, getters, dispatch }) {
      await dispatch('get_full_tree');
      commit('SET_IS_EXPANDED_FLAG_TO_ALL_NODES', !getters.getIsTreeExpanded);
      commit('SET_IS_TREE_EXPANDED', !getters.getIsTreeExpanded);
      commit('RESET_NODE_TREE_STATE');
    },
    /**
     * Sending the changed resources to the backend
     * @param {*} param0
     * @param {*} data
     */
    async update_resources({ commit }, data) {
      try {
        await store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'workloadManagement.updateResourcesMessage',
          color: 'success',
          showClose: true,
        });
        await WorkloadsApiService.updateResources(data);
      } catch (err) {
        await store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: err.response.data[0].message,
          color: 'red',
          showClose: true,
        });
        commit('SET_RESPONSE', { status: 'FAIL', message: err.response.data.msg });
        throw err;
      }
    },

    /**
     * Set version id of selected wl
     * @param commit
     * @param {*} versionId
     */
    async set_version_id_of_selected_wl({ commit }, versionId) {
      commit('SET_VERSION_ID_OF_SELECTED_WL', versionId);
    },

    set_cancel_token({ commit }, cancelToken) {
      commit('SET_CANCEL_TOKEN', cancelToken);
    },
    set_cancel_token_start_sending({ commit }, cancelToken) {
      commit('SET_CANCEL_TOKEN_START_SENDING', cancelToken);
    },
    set_cancel_token_dna_status({ commit }, cancelToken) {
      commit('SET_CANCEL_TOKEN_DNA_STATUS', cancelToken);
    },
    async parseAndSetEnvVarsAndPorts({ commit }, payload) {
      const { envVars, ports } = payload;
      const environmentVariables = await WorkloadsHelper.parseEnvVars(envVars);
      const portList = await WorkloadsHelper.parsePorts(ports);
      commit('SET_PROP_DEPLOYED_WORKLOAD', {
        propName: '_environmentVariables',
        propValue: environmentVariables,
      });
      commit('SET_PROP_DEPLOYED_WORKLOAD', { propName: '_portList', propValue: portList });
    },
    service_action_clicked({ state }, service) {
      store.dispatch('utils/_api_request_handler/show_apply_workload_configuration_dialog', {
        title: state.selectedDeployedWorkload.name,
        service,
      });
    },
    get_docker_inspect_result(_, payload) {
      return NodesApiService.getDataFromNode(payload);
    },
    set_is_new_compose_supported({ commit }, value) {
      commit('SET_IS_NEW_COMPOSE_SUPPORTED', value);
    },
    async handle_rerouting_to_node_tree({ state, commit, dispatch, getters }, payload) {
      const { searchTerm, searchParam, treeNode, workloadControlPermission } = payload;
      if (!treeNode || !treeNode.data) {
        return;
      }
      state.selectedNode = {};
      await commit('SET_IS_SELECTED_FLAG_TO_FALSE_TO_ALL_NODES');
      await dispatch('search_node_tree', {
        searchParam,
        searchTerm,
        fullTreeAlreadyExists: true,
        highlightWithoutTimeout: true,
      });
      await commit('SET_SELECTED_NODE', treeNode.data[0].data.device);
      const oldSelectedDevice = getters.getSelectedNode;
      await NodeTreeHelper.setSelectedNode(treeNode.data[0].data.device);
      await NodeTreeHelper.fetchNodeDetails(treeNode.data[0].data.device, workloadControlPermission).then(
        async (apiProvidedDevice) => {
          commit('SET_TREE_NODE_DEVICE', { device: apiProvidedDevice, setSelectedNode: true });
          const newSelectedDevice = new NodeModel(treeNode.data[0].data.device);
          await NodeTreeHelper.startEmitting({ newSelectedDevice });
          await mqtt.unsubscribeFrom('node', oldSelectedDevice);
          await mqtt.subscribeTo('node', newSelectedDevice);
        },
      );
      state.selectedTreeNodes = [state.selectedNode];
      await NodeTreeHelper.invokeMqttEvents();
    },
    /**
     * @description Resets node tree state to default values
     * @param commit
     */
    reset_state_to_default_values({ commit }) {
      commit('RESET_STATE_TO_DEFAULT_VALUES');
    },

    set_table_search_value({ commit }, value) {
      commit('SET_WORKLOAD_TABLE_SEARCH_VALUE', value);
    },
    /**
     * @description Remove type node, clear selected node, clear backup nodes
     * @param getters
     * @param commit
     * @param dispatch
     * @param serialNumber - Serial number of node which has been removed
     * @returns {Promise<void>}
     */
    async offboard_node({ getters, commit, dispatch }, serialNumber) {
      const treeNode = NodeTreeHelper.findRecursively(getters.getNodes, 'device.serialNumber', serialNumber);
      if (!treeNode) {
        return;
      }
      if (treeNode.data.device.connectionStatus === 'online') {
        NodeTreeHelper.clearExchangeInterval();
        mqtt.unsubscribeFrom('node', treeNode);
      }
      if (getters.getSelectedNode.serialNumber === serialNumber) {
        commit('SET_SELECTED_NODE', new NodeModel({}));
        await store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'errorMessages.nodeOffboarded',
          color: 'red',
          showClose: true,
        });
      }
      commit('REMOVE_NODE', treeNode);
      commit('SET_BACKUP_NODES', []);
      await dispatch('update_unassigned');
    },
    set_query_params({ commit }, params) {
      commit('SET_QUERY_PARAMS', params);
    },
  },
};
