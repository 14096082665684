/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2020. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import { set } from '@/utils/vuex';
import ActivateProfileApiService from '@/services/api/activate-profile.api-service';

export default {
  state: {
    isActivated: false,
    mfaActivationToken: null,
  },
  mutations: {
    SET_IS_ACTIVATED: set('isActivated'),
    SET_MFA_ACTIVATION_TOKEN: set('mfaActivationToken'),
  },
  getters: {
    isActivated: (state) => state.isActivated,
    mfaActivationToken: (state) => state.mfaActivationToken,
  },
  actions: {
    async isActivated({ commit }, param) {
      const res = await ActivateProfileApiService.isActivated(param);
      commit('SET_IS_ACTIVATED', res.isActivated);
    },
    async profileActivation({ commit }, params) {
      const res = await ActivateProfileApiService.profileActivation(params);
      commit('SET_MFA_ACTIVATION_TOKEN', res.mfaActivationToken);
    },
  },
};
