/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

// import store from '@/store'
import { ForgotPasswordApiService } from '@/services/api';
import { set } from '@/utils/vuex';

export default {
  state: {
    checkIfExisting: false,
  },

  getters: {
    getCheck: (state) => state.checkIfExisting,
  },

  mutations: {
    SET_CHECK: set('checkIfExisting'),
  },

  actions: {
    async checkIfExisting({ commit }, params) {
      const res = await ForgotPasswordApiService.checkIfExisting(params);
      commit('SET_CHECK', res.success);
    },

    async resetCredentials(_, params) {
      await ForgotPasswordApiService.resetCredentials(params);
    },
  },
};
