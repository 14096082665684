import BaseApiService from '@/services/api/base.api-service';

const PATH = {
  MFA_GENERATION: '/auth/mfa/generate',
  MFA_ACTIVATION: '/auth/mfa/activate',
  MFA_RESET: '/auth/mfa/reset',
  IS_MFA_ENABLED: '/auth/mfa/is-enabled',
};

class MultiFactorAuthenticationService extends BaseApiService {
  generateMFASecret(params) {
    return this.get(`${PATH.MFA_GENERATION}/${params.generationToken}`);
  }

  activateMFA(params) {
    return this.post(`${PATH.MFA_ACTIVATION}/${params.activationToken}`, { mfaSecret: params.mfaSecret });
  }

  resetMFA(params) {
    return this.post(`${PATH.MFA_RESET}/${params.resetToken}`, { mfaSecret: params.mfaSecret });
  }

  isMFAEnabled() {
    return this.get(`${PATH.IS_MFA_ENABLED}`);
  }
}

export default new MultiFactorAuthenticationService();
