/* eslint-disable no-control-regex */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

/**
 * @property {number} MAX_LENGTH_CLOUD_URL
 * @property {number} CHARACTER_COUNTER
 * @property {number} SERIAL_NUMBER_LENGTH
 * @property {number} UNIT_OF_INFORMATION
 * @property {number} URL_LENGTH
 * @property {String} NETWORK_CONFIGURATION
 * @property {String} MAX_LENGTH_REPOSITORY_URL
 * @property {Object} REPOSITORY_USERNAME
 * @property {Object} REPOSITORY_PASSWORD
 * @property {Object} IP_ADDRESS
 * @property {Object} VALIDATION_REGEX
 * @property {Object} PROTOCOLS
 * @property {Object} ROLE_NAME
 * @property {Object} ROLE_DESCRIPTION
 * @property {number} NOTIFICATION_HEADER_TEXT
 * @property {number} NOTIFICATION_TEXT_MESSAGE
 * @property {number} MAX_LENGTH_NAME
 * @property {number} WORKLOAD_DETAIL_DESCRIPTION_LENGTH
 * @property {number} MAX_VALUE_OF_PORT
 * @property {number} MIN_VALUE_OF_PORT
 * @property {number} REMOTE_CONNECTION_PORT_SSH
 * @property {number} REMOTE_CONNECTION_PORT_VNC
 * @property {number} REMOTE_CONNECTION_PORT_RDP
 * @property {number} MAX_LENGTH_WORKLOAD_VERSION_NAME
 * @property {number} MAX_LENGTH_WORKLOAD_VERSION_RELEASE_NAME
 * @property {Object} CPU_RESOURCE
 * @property {Object} NUMBER_OF_VIRTUAL_CPU
 * @property {Object} DNA_STATUSES
 * @property {Array} HYPERVISOR
 */

const MAX_LENGTH_CLOUD_URL = 255;
const CHARACTER_COUNTER = {
  SECUREID: 16,
  SERIALNUMBER: 12,
};
const SERIAL_NUMBER_LENGTH = 12;
const UNIT_OF_INFORMATION = 1000;
const URL_LENGTH = 100;
const NETWORK_CONFIGURATION = 'static';
const MAX_LENGTH_REPOSITORY_URL = 255;
const MAX_LENGTH_SECURE_ID = 16;
const KEY_VALUE_LABEL_LENGTH = 40;
const NOTIFICATION_HEADER_TEXT = 60;
const NOTIFICATION_TEXT_MESSAGE = 10000;
const MAX_LENGTH_NAME = 40;
const WORKLOAD_DETAIL_DESCRIPTION_LENGTH = 300;
const MAX_LENGTH_WORKLOAD_VERSION_NAME = 40;
const MAX_LENGTH_DOCKER_NETWORK_NAME = 40;
const MAX_LENGTH_WORKLOAD_VERSION_RELEASE_NAME = 40;
const DURATION = 10 * 3600; // 10 hours
const DATA_INTERVAL = 5000;
const WATCHDOG = 20000;
const HYPERVISOR = ['Virtualization via KVM', 'Virtualization via ACRN', 'Virtualization via XEN'];
const AUDIT_LOGS = 'Audit Logs';

const VALIDATION_REGEX = {
  EMAIL:
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
  SERIAL_NUMBER: /^[a-zA-Z0-9]*$/,
  SECURITY_ID: /^[a-zA-Z0-9]*$/,
  NODE_NAME: /^\s*(?=[a-zA-Z0-9_ -]+$)\S.*$/,
  NEW_PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
  SERVER_PORT:
    /^0*([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-4])$/,
  NO_CONTROL_CHARACTERS: /^[^\u0000-\u001f\u007f-\u009f]*$/,
  ONLY_LETTERS_AND_NUMBERS: /^[a-zA-Z0-9 ]*$/,
  PORT_RANGE:
    /^0*([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
  INTERVAL_REGEX: /^([1-9]|[12][0-9]|3[01])*$/,
  DEPLOY_NAME_PATTERN: /^[^\u0000-\u001f\u007f-\u009f]*$/,
  LETTER_NUMBER_DASH_AND_UNDERSCORE: /^[a-zA-Z0-9_-]+$/,
  PREVENT_WHITESPACE: /^[^' ']*$/,
  CONTAINER_NAME_PATTERN: /^[a-zA-Z0-9][a-zA-Z0-9_-]+$/,
  ONLY_NUMBER: /^[0-9]+$/,
  LETTER_NUMBER_AND_UNDERSCORE: /^[a-zA-Z0-9_]+$/,
  PCI_PASSTHROUGH: /^(pci_)[a-f0-9]{4}_[a-f0-9]{2}_[a-f0-9]{2}_[a-f0-9]{1}$/,
  MEMORY_INPUT: /^(((?!(0))\d+)|(\d+\.{1}\d{1,5}))$/,
  DATA_DISK_SIZE: /^(((?!(0))\d+)|(\d+\.{1}\d{1,5}))$/,
  DOCKER_VOLUME_NAME: /^[A-z0-9-.]*$/,
  DOCKER_VOLUME_PATH: /^[A-z0-9/.-]*$/,
  DOCKER_IMAGE_PATH: /^(([\w.-]+:\d+\/)?(?:[\w.-]+\/)+)?[\w.-]+(?::[\w.-]+)?$/,
  DOCKER_USERNAME: /^[^\u0000-\u001f\u007f-\u009f:]*$/,
  VARIABLE_MAPPING_INFORMATION: /^[a-zA-Z_]+[a-zA-Z0-9_]*$/,
  LABEL_REGEX: /^[a-zA-Z0-9_-]*$/,
  DOCKER_VOLUME_NAME_GENERATE_NAME: /^[a-zA-Z0-9][a-zA-Z0-9_.-]+$/,
  NO_WHITESPACE_AT_THE_BEGINNING: /^\S.*$/,
  STRING_CONTAINS_ONLY_SPACES: /^\s*$/,
  RC_HOSTNAME: /^[a-zA-Z0-9][a-zA-Z0-9_.-]*$/,
  TOTP: /^\d{6}$/,
};

const PROTOCOLS = {
  WSS: 'wss',
  SSL: 'ssl',
  HTTP: 'http',
  HTTPS: 'https',
};

const IP_ADDRESS = {
  MIN_LENGTH: 0,
  MAX_LENGTH: 255,
};

const ACTIVATE_PROFILE = {
  MIN_LENGTH: 8,
  MAX_LENGTH: 100,
};

const DOCKER_PASSWORD = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 4000,
};

const DOCKER_USERNAME_LENGTH = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 4000,
};

const MINUTES = {
  MIN_LENGTH: 0,
  MAX_LENGTH: 59,
};

const HOURS = {
  MIN_LENGTH: 0,
  MAX_LENGTH: 23,
};

const RESET_PASSWORD = {
  MIN_LENGTH: 8,
  MAX_LENGTH: 100,
};

const TREE_NODE_FOLDER_NAME = {
  MIN_LENGTH: 2,
  MAX_LENGTH: 40,
};

const USER_PASSWORD = {
  MIN_LENGTH: 8,
  MAX_LENGTH: 100,
};

const REPOSITORY_PASSWORD = {
  MIN_LENGTH: 8,
  MAX_LENGTH: 40,
};

const ROLE_NAME = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 64,
};

const ROLE_DESCRIPTION = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 64,
};
const DEPLOY_NAME = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 40,
};
const MAX_LENGTH_TIMEZONE_FIELD = 54;

const CPU_RESOURCE = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 25600,
};

const NUMBER_OF_VIRTUAL_CPU = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 256,
};

const VOLUMES_NAME = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 1000,
};

const VOLUMES_PATH = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 1000,
};

const WORKLOAD_VERSION_ENVIRONMENT_VARIABLE_NAME = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 1024,
};
const WORKLOAD_VERSION_ENVIRONMENT_VARIABLE_VALUE = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 4000,
};
const URL_MATCHER = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
const ALLOWED_EXTENSIONS = ['jpg', 'png', 'jpeg'];
const MAX_FILE_SIZE = 5000000; // 5 MB in bytes
const MAX_VALUE_OF_PORT = 65535;
const MIN_VALUE_OF_PORT = 0;
const REMOTE_CONNECTION_PORT_SSH = 22;
const REMOTE_CONNECTION_PORT_VNC = 5900;
const REMOTE_CONNECTION_PORT_RDP = 3389;

const MEASURES = [
  {
    measure: 'KB',
    min: 100000,
    max: 20000000,
  },
  {
    measure: 'MB',
    min: 100,
    max: 20000,
  },
  {
    measure: 'GB',
    min: 0.1,
    max: 20,
  },
  {
    measure: 'TB',
    min: 0.00009999999999999999,
    max: 0.02,
  },
];

const MEASURES_DISK = [
  {
    measure: 'KB',
    min: 100000,
    max: 32000000,
  },
  {
    measure: 'MB',
    min: 100,
    max: 32000,
  },
  {
    measure: 'GB',
    min: 0.1,
    max: 32,
  },
  {
    measure: 'TB',
    min: 0.0001,
    max: 0.032,
  },
];
const STATUSES = {
  NO_CODESYS: -1,
  IDLE: 0,
  CREATING: 1,
  REMOVING: 2,
  SUSPENDING: 3,
  SUSPENDED: 4,
  STARTING: 5,
  RESTARTING: 6,
  RESUMING: 7,
  STARTED: 8,
  STOPPING: 9,
  STOPPED: 10,
  ERROR: 11,
  REMOVING_FAILED: 12,
  PARTIALLY_RUNNING: 13,
};

const TYPE_OF_WORKLOADS = {
  DOCKER: 'docker',
  VM: 'vm',
  CODESYS: 'codesys',
  COMPOSE: 'docker-compose',
};

const DNA_STATUSES = {
  initial: 'Initial',
  applied: 'Applied',
  canceling: 'Canceling',
  modified: 'Modified',
  reconfiguring: 'Reconfiguring',
  undefined: 'Undefined',
  n_a: 'n/a',
};

const REMOTE_CONNECTION = {
  NUMBER_OF_CONNECTIONS_MIN_VALUE: 1,
  NUMBER_OF_CONNECTIONS_MAX_VALUE: 100,
  AUTO_RETRY_MIN_VALUE: 1,
  AUTO_RETRY_MAX_VALUE: 3,
};

const RC_APPROVAL_ON_NODE = {
  LOCAL_APPROVAL_SET_BY_MS: 0,
  AUTO_APPROVE: 1,
  LOCAL_APPROVAL: 2,
};

export {
  MAX_LENGTH_CLOUD_URL,
  CHARACTER_COUNTER,
  SERIAL_NUMBER_LENGTH,
  UNIT_OF_INFORMATION,
  URL_LENGTH,
  NETWORK_CONFIGURATION,
  MAX_LENGTH_REPOSITORY_URL,
  TREE_NODE_FOLDER_NAME,
  REPOSITORY_PASSWORD,
  IP_ADDRESS,
  VALIDATION_REGEX,
  PROTOCOLS,
  USER_PASSWORD,
  MAX_LENGTH_SECURE_ID,
  KEY_VALUE_LABEL_LENGTH,
  ROLE_NAME,
  ROLE_DESCRIPTION,
  NOTIFICATION_HEADER_TEXT,
  NOTIFICATION_TEXT_MESSAGE,
  MAX_LENGTH_NAME,
  DEPLOY_NAME,
  ACTIVATE_PROFILE,
  RESET_PASSWORD,
  URL_MATCHER,
  ALLOWED_EXTENSIONS,
  MAX_FILE_SIZE,
  WORKLOAD_DETAIL_DESCRIPTION_LENGTH,
  MAX_VALUE_OF_PORT,
  MIN_VALUE_OF_PORT,
  REMOTE_CONNECTION_PORT_SSH,
  REMOTE_CONNECTION_PORT_VNC,
  REMOTE_CONNECTION_PORT_RDP,
  MINUTES,
  HOURS,
  MAX_LENGTH_TIMEZONE_FIELD,
  MAX_LENGTH_WORKLOAD_VERSION_NAME,
  MAX_LENGTH_WORKLOAD_VERSION_RELEASE_NAME,
  CPU_RESOURCE,
  NUMBER_OF_VIRTUAL_CPU,
  MEASURES,
  DOCKER_PASSWORD,
  DOCKER_USERNAME_LENGTH,
  MEASURES_DISK,
  DURATION,
  DATA_INTERVAL,
  WATCHDOG,
  MAX_LENGTH_DOCKER_NETWORK_NAME,
  VOLUMES_NAME,
  VOLUMES_PATH,
  WORKLOAD_VERSION_ENVIRONMENT_VARIABLE_NAME,
  WORKLOAD_VERSION_ENVIRONMENT_VARIABLE_VALUE,
  STATUSES,
  TYPE_OF_WORKLOADS,
  DNA_STATUSES,
  HYPERVISOR,
  REMOTE_CONNECTION,
  RC_APPROVAL_ON_NODE,
  AUDIT_LOGS,
};
