/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import BaseApiService from './base.api-service';

const REMOTE_CONNECT = 'nerve/remote-connections/connect/';

const CANCEL_REMOTE_CONNECT = 'nerve/active-remote-connections/cancel-connection-approval';

const GUACAMOLE_CONNECTION_TAB = 'nerve/guacamole-connection-tab';

const IMPORT_RC = 'nerve/v2/remote-connections-file';

class RemoteConnectionApiService extends BaseApiService {
  remoteConnect(data) {
    return this.post(`${REMOTE_CONNECT}`, data, { authRequired: true });
  }

  cancelRemoteConnApproval(activeConnRequestUid) {
    return this.delete(`${CANCEL_REMOTE_CONNECT}/${activeConnRequestUid}`, { authRequired: true });
  }

  guacamoleConnectionTab(data) {
    return this.post(`${GUACAMOLE_CONNECTION_TAB}/${data.authToken}/${data.identifier}`, data, {
      authRequired: true,
    });
  }

  importRemoteConnections(params) {
    return this.post(`${IMPORT_RC}/${params.target}/validate`, params.formData, { authRequired: true });
  }
}

export default new RemoteConnectionApiService();
